<template>
    <v-card>
        <app-scan-table-display :scan="scan" :institute="institute"></app-scan-table-display>
        <v-card-actions>
            <v-btn @click="restoreScan(scan)">
                <v-icon style="color: red;font-size: 30px;">mdi-file-restore
                </v-icon>
                wiederherstellen
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

import ScanTableDisplay from "@/components/Scan/ScanTableDisplay.vue";

export default {
    components: {
        appScanTableDisplay: ScanTableDisplay
    },
    data() {
        return {
            dialog: true,
            notifications: false,
            sound: true,
            widgets: false,
        }
    },
    methods: {
        restoreScan(scan) {
            this.$store.dispatch("restoreScan", scan.id)
        },
    },
    computed: {
        institute() {
            return this.$store.getters.getInfo
        }
    },
    props: {
        scan: {
            type: Object,
            required: true
        }
    }
}
</script>
<template>
    <v-card style="
    flex-direction: column; max-height: 100%; height: 100%;">
        <v-row style="max-height: 92%;align-content: flex-start;" class="d-flex flex-grow-1">
            <v-toolbar flat>
                <v-card-title>Aktuelle auswahl:</v-card-title>
                <div v-if="!isEditing">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs"
                                    v-on="on" outlined @click="isEditing = true">{{ value.ospResource }}
                                <v-icon small right>
                                    mdi-pencil
                                </v-icon>
                            </v-chip>
                        </template>
                        <span>Manuell bearbeiten</span>
                    </v-tooltip>
                </div>
                <div v-else>
                    <v-text-field append-outer-icon="mdi-content-save" v-model="value.ospResource"
                                  @click:append-outer="setPerson(value.ospResource)"></v-text-field>
                </div>
            </v-toolbar>
            <app-multiple-finds :scan="value" :potentialCustomerEnabled="institute.potentialCustomerCreate" :displayAddressData="displayAddressData"
                                :potentialCustomerAlreadyCreated="value.potentialCustomerCreated"
                                :highlight="value.ospResource" :persons="OspPersons" @setPerson="setPerson"
                                @makeNewPotentialCustomer="createPotentialCustomerDialog = true" @addAddressData="addAddressData"></app-multiple-finds>
            <app-not-found-dialog v-model="notFoundDialog"/>
            <app-potential-customer-data :scan="value"  v-model="createPotentialCustomerDialog" @loadSvzs="loadSvzs"
                                         :personsFoundHint="OspPersons.length !== 0"
                                         @createPotentialCustomer="createPotentialCustomer"
                                         :potential-customer-loading="potentialCustomerDialogLoad"
                                         :is-company="isCompany" :svzs="svzs"></app-potential-customer-data>
        </v-row>
        <v-card-actions style="height: 8%">
            <v-btn color="primary" @click="back">
                Zurück
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="value.ospResource == null" color="primary" @click="next">
                Weiter
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import appMultipleFinds from "@/components/Scan/MultipleFinds.vue";
import appNotFoundDialog from "@/components/Scan/NotFoundDialog.vue";
import appPotentialCustomerData from "@/components/Scan/PotentialCustomerData.vue";

export default {
    components: {
        appNotFoundDialog, appMultipleFinds, appPotentialCustomerData
    },
    data() {
        return {
            isEditing: false,
            potentialCustomerDialogTitle: "",
            potentialCustomerDialog: false,
            notFoundDialog: false,
            multipleFinds: false,
            createPotentialCustomerDialog: false,
            inputRules: [
                v => v.length <= 100 || 'Maximal 100 Zeichen',
                v => /[a-z0-9]/i.test(v) || "Mindestens ein Zeichen"
            ]
        }
    },
    props: {
        search: {
            required: true,
            type: Boolean
        },
        value: {
            required: true,
            type: Object
        },
        OspPersons: {
            type: Array
        },
        potentialCustomerDialogLoad: {
            required: true,
            type: Boolean
        },
        isCompany: {
            required: true,
            type: Boolean
        },
        svzs: {
            required: true,
            type: Array
        },
        institute: {
            required: true,
            type: Object
        },
        displayAddressData: {
            required: true,
            type: Boolean
        }
    },
    watch: {
        search() {
            if (this.search) {
                this.isEditing = false
                this.notFoundDialog = false
                this.potentialCustomerDialog = false
                this.createPotentialCustomerDialog = false
                this.$store.dispatch("loadOspperson", {
                    firstName: this.value.scanData.firstNames.fieldValue,
                    lastName: this.value.scanData.lastName.fieldValue,
                    birthday: this.value.scanData.dateOfBirth.fieldValue
                }).then(() => {
                    if (this.OspPersons.length === 0) {
                        if (!this.institute.potentialCustomerCreate) {
                            this.notFoundDialog = true
                        }
                    } else if (this.OspPersons.length === 1 && (this.value.ospResource == null || this.value.ospResource === "")) {
                        this.setPerson(this.OspPersons[0].personNumber)
                    } else {
                        this.notFoundDialog = false
                        this.potentialCustomerDialog = false
                    }
                })
            }
        },
    },
    methods: {
        createPotentialCustomer(data) {
            this.$store.dispatch("createPotentialCustomer", {id: this.value.id, data: data}).then(() => {
                this.createPotentialCustomerDialog = false
                this.next()
            })
        },
        loadSvzs() {
            this.$emit("loadSvzs")
        },
        setPerson(personNumber) {
            this.$emit("setOspResource", {
                ospResourceID: personNumber,
                scanId: this.value.id
            })
            this.isEditing = false
        },
        addAddressData(){
            this.value.scanData.manualAddressData = true
            this.$emit("backScrollToAddress")
        },
        back() {
            this.$emit("back")
        },
        next() {
            this.$emit("next")
        }
    }
}
</script>
<style scoped>
</style>
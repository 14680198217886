<template>
    <div>
        <template v-if="scan.security.securityStatus === 'EXPIRED'">
            <v-dialog v-model="dialog" width="500">
                <v-card>
                    <v-card-title class="primary error-dialog-title">
                        Dokument abgelaufen
                    </v-card-title>

                    <v-card-text >
                        Das Dokument ist abgelaufen. Es kann nicht zur Legitimation verwendet werden.
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click.stop="close">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-else>
            <app-fullscreen-dialog dialog :title="title" @close="close">
                <template v-if="scan.status === 'NEU'">
                    <template
                            v-if="scan.scanData.dtype.fieldValue === undefined || scan.scanData.dtype.fieldValue === ''">
                        <app-pick-component :scan="scan" @save="saveDocumentType"></app-pick-component>
                    </template>
                    <template v-else>
                        <app-scan-edit-new-dialog @close="close" :scan="scan" :countries="countries"
                                                  :isCompany="isCompany"></app-scan-edit-new-dialog>
                    </template>

                </template>
                <template v-if="scan.status ==='BEARBEITET'">
                    <app-scan-display :scan="scan"></app-scan-display>
                </template>
                <template v-if="scan.status ==='GELOESCHT'">
                    <app-scan-delete-display :scan="scan"></app-scan-delete-display>
                </template>
            </app-fullscreen-dialog>
        </template>
    </div>
</template>
<script>
import ScanDisplay from "@/components/Scan/ScanDisplay.vue";
import PickDocumentComponent from "./PickDocument.vue"
import ScanDeleteDisplay from "@/components/Scan/ScanDeleteDisplay.vue";
import ScanEditNewDialog from "@/components/Scan/ScanEditDialog.vue";
import appFullscreenDialog from "@/components/Common/FullscreenDialog.vue";

export default {
    props: {
        scan: Object,
        countries: Array,
        isCompany: Boolean
    },
    data() {
        return {
            dialog: true
        }
    },
    computed: {
        title() {
            switch (this.scan.status) {
                case 'NEU':
                    return "Legitimationsdokument bearbeiten"
                case 'BEARBEITET':
                    return `Bearbeitet: ${this.scan.scanData.firstNames.fieldValue} ${this.scan.scanData.lastName.fieldValue}(${this.scan.scanData.documentNumber.fieldValue})`
                case 'GELOESCHT':
                    return `Gelöscht: ${this.scan.scanData.firstNames.fieldValue} ${this.scan.scanData.lastName.fieldValue}(${this.scan.scanData.documentNumber.fieldValue})`
                default:
                    return ""
            }
        }
    },
    methods: {
        close() {
            this.$emit("closeEdit")
        },
        saveDocumentType(documentType) {
            this.$emit("saveDocumentType", documentType)
        }
    },
    components: {
        appFullscreenDialog,
        appPickComponent: PickDocumentComponent,
        appScanDisplay: ScanDisplay,
        appScanDeleteDisplay: ScanDeleteDisplay,
        appScanEditNewDialog: ScanEditNewDialog
    }
}
</script>
<template>
    <div class="text-center">
        <v-dialog v-model="show" width="520">
            <v-card>
                <v-card-title class="primary mini-dialog-title">
                    Abweichenden Geburtsnamen im OSPlus gefunden
                </v-card-title>
                <v-card-text>
                    <v-card-subtitle>
                        Wollen Sie die Abweichung ins OSPlus übertragen?
                    </v-card-subtitle>
                    <v-row>
                      <v-text-field v-model="item.scanData.birthName.fieldValue" label="Geburtsname" type="text" disabled></v-text-field>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="loading" color="primary" text @click.stop="yes">
                        Ja
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="loading" text @click.stop="no">
                        Nein
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            additionalFirstNamesResponse: ""
        }
    },
    props: {
        show: Boolean,
        item: Object,
        loading:Boolean
    },
    methods: {
        yes() {
            this.$emit("updateBirthName", this.item)
        },
        no() {
            this.$emit("notUpdateBirthName")
        },
    }
}
</script>

<style scoped>

.mini-dialog-title {
    color: white;
}
</style>
<style>
.chips-container {
    margin-bottom: 20px;
}
</style>
<script setup>
export default {
    props: {
        scan: {
            type: Object,
            required: true
        },
        institute: {
            type: Object,
            required: true
        }
    }
}
</script>

<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
            <tr>
                <th class="text-left">
                    Feld
                </th>
                <th class="text-left">
                    Wert
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Dokumentenart</td>
                <td>{{ scan.scanData.dtype.fieldValue }}</td>
            </tr>
            <tr>
                <td>Nummer</td>
                <td>{{ scan.scanData.documentNumber.fieldValue }}</td>
            </tr>
            <tr>
                <td>Vorname(n)</td>
                <td>{{ scan.scanData.firstNames.fieldValue }}</td>
            </tr>
            <tr>
                <td>Nachname</td>
                <td>{{ scan.scanData.lastName.fieldValue }}</td>
            </tr>
            <tr>
                <td>Geburtsdatum</td>
                <td>{{ scan.scanData.dateOfBirth.fieldValue }}</td>
            </tr>
            <tr>
                <td>Staatsangehörigkeit</td>
                <td>{{ scan.scanData.nationality.fieldValue }}</td>
            </tr>
            <tr>
                <td>Ausstellende Behörde</td>
                <td>{{ scan.scanData.issuer.fieldValue }}</td>
            </tr>
            <tr>
                <td>Ausstellende Behörde (Land)</td>
                <td>{{ scan.scanData.issuerCountry.fieldValue }}</td>
            </tr>
            <tr>
                <td>Ausstellungsdatum</td>
                <td>{{ scan.scanData.issuedAt.fieldValue }}</td>
            </tr>
            <tr>
                <td>Ablaufdatum</td>
                <td>{{ scan.scanData.validUntil.fieldValue }}</td>
            </tr>
            <tr v-if="scan.status == 'BEARBEITET'">
                <td>Bearbeitungsart</td>
                <td>{{ scan.autoLegitimized ? 'Automatisch verarbeitet' : 'Manuell bearbeitet' }}</td>
            </tr>
            <template v-if="scan.scanData.address && institute.considerAddress !== 'DISABLED'">
                <tr>
                    <td>Wohnort</td>
                    <td>{{ scan.scanData.address.location.fieldValue }}</td>
                </tr>
                <tr>
                    <td>Straße</td>
                    <td>{{ scan.scanData.address.street.fieldValue }}</td>
                </tr>
                <tr>
                    <td>Hausnummer</td>
                    <td>{{ scan.scanData.address.houseNumber.fieldValue }}</td>
                </tr>
                <tr>
                    <td>PLZ</td>
                    <td>{{ scan.scanData.address.postcode.fieldValue }}</td>
                </tr>
            </template>
            </tbody>
        </template>
    </v-simple-table>
</template>
<template>
    <div class="default-background">
        <router-view></router-view>
        <app-scan-table :items="items" @editScan="editScan"></app-scan-table>
    </div>
</template>
<script>
import ScanTableComponent from "../../components/Scan/ScanTable.vue"

export default {
    components: {
        appScanTable: ScanTableComponent
    },
    computed: {
        items() {
            return this.$store.getters.getScans;
        }
    },
    methods: {
        editScan(scanID) {
            this.$router.push({
                name: "ScanEdit",
                params: {
                    scanID: scanID
                }
            });
        }
    },
    created() {
        this.$store.dispatch("loadScans")
        this.$store.dispatch("loadCountries")
    }
}
</script>
<style scoped>
.default-background {
    background-color: #f0f0f0 !important;
    height: 100%;
    min-height: 0;
    display: flex;
}
</style>
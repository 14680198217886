import Vue from 'vue'
import VueRouter from 'vue-router'
import ScanIndex from '../views/Scan/Index.vue'
import ScanShow from "../views/Scan/Show.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "ScanIndex",
        component: ScanIndex,
        children: [
            {
                path: "scan/:scanID",
                name: "ScanEdit",
                component: ScanShow
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        name: 'Anwendung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ScanIndex
    },
]

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes
})

export default router

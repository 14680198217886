<template>
    <v-card flat class="home-tab-outer-wrapper">
        <v-data-iterator class="scan-data-iterator" :items="filteredItems" :items-per-page.sync="itemsPerPage"
                         :page.sync="page"
                         hide-default-footer>

            <template v-slot:header>
                <v-toolbar dark class="row-toolbar">
                    <v-text-field v-model="search" solo-inverted hide-details prepend-inner-icon="mdi-magnify"
                                  label="Suche"
                                  id="row-search-field"></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select v-model="onlyShow" return-object flat solo-inverted hide-details :items="status"
                                  item-text="displayText" prepend-inner-icon="mdi-filter-menu-outline"
                                  label="Sort by"></v-select>
                        <v-spacer></v-spacer>
                        <v-btn-toggle id="row-button-toggle" v-model="sortDesc" mandatory>
                            <v-btn large depressed :value="false" @click.stop="loadImages()">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </template>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-dialog v-model="restoreDialog" width="500">
                    <v-card>
                        <v-card-title style="color:white" class="primary">
                            Scan Wiederherstellen
                        </v-card-title>

                        <v-card-text>
                            Wollen Sie den ausgewählten Scan wirklich wiederherstellen?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="restoreDialog = false">
                                Abbrechen
                            </v-btn>
                            <v-btn color="primary" text @click="restoreScan()">
                                Wiederherstellen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="deleteDialog" width="500">
                    <v-card>
                        <v-card-title style="color:white" class="primary">
                            Scan löschen
                        </v-card-title>

                        <v-card-text>
                            Wollen Sie den ausgewählten Scan wirklich löschen?
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="deleteDialog = false">
                                Abbrechen
                            </v-btn>
                            <v-btn color="primary" text @click="deleteScan()">
                                Löschen
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-row class="data-iterator-row">
                    <v-col v-for="(item,i) in props.items" :index="i" :key="item.name" cols="12" sm="12" md="12"
                           lg="12">
                        <v-card @click.stop="triggerActionDialog(item)" class="row-card-wrapper">
                            <div style="display:flex;width: fit-content;position: absolute;right: 0px;top: 0px;padding:5px">
                                <div v-if="item.status.toLowerCase() === 'bearbeitet'">
                                    <v-icon @click.stop="downloadPdf(item)" style="color: red;font-size: 30px;">
                                        mdi-file-pdf-outline
                                    </v-icon>
                                </div>
                                <div v-if="item.status.toLowerCase() == 'neu'">

                                    <v-icon @click.stop="triggerDeleteDialog(item.id)"
                                            style="color: red;font-size: 30px;">mdi-delete
                                    </v-icon>
                                </div>
                                <div v-if="item.status.toLowerCase() == 'geloescht'">
                                        <v-btn icon @click.stop="triggerRestoreDialog(item.id)">
                                            <v-icon
                                                style="color: red;font-size: 30px;">mdi-file-restore
                                            </v-icon>
                                        </v-btn>
                                </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="cardview-row-wrapper">
                                <div class="image-row-wrapper">
                                    <v-img v-if="item.images[0]" :src="item.images[0].processedImage">
                                    </v-img>
                                </div>
                                <div class="text-row-wrapper">
                                    <v-card-title class="subheading font-weight-bold">
                                        {{ epochToDate(item.createdAt) }}
                                    </v-card-title>
                                    <div class="text-row-wrapper">
                                        <v-list dense>
                                            <v-list-item>
                                                <v-list-item-content class="v-list-item__content_description">
                                                    Status
                                                </v-list-item-content>
                                                <v-list-item-content class="v-list-item__content_value">
                                                    {{ getStatusText(item.status.toLowerCase()) }}
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content class="v-list-item__content_description">
                                                    Art der Legitimation
                                                </v-list-item-content>
                                                <v-list-item-content class="v-list-item__content_value">
                                                    {{ docType(item.dtype.fieldValue) }}
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content class="v-list-item__content_description">
                                                    Name
                                                </v-list-item-content>
                                                <v-list-item-content class="v-list-item__content_value">
                                                    <div v-if="item.scanData.lastName">
                                                        {{ item.scanData.lastName.fieldValue }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content class="v-list-item__content_description">
                                                    Vorname
                                                </v-list-item-content>
                                                <v-list-item-content class="v-list-item__content_value">
                                                    <div v-if="item.scanData.firstNames">
                                                        {{ item.scanData.firstNames.fieldValue }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content class="v-list-item__content_description">
                                                    Geburtsdatum
                                                </v-list-item-content>
                                                <v-list-item-content class="v-list-item__content_value">
                                                    <div v-if="item.scanData.dateOfBirth">
                                                        {{ item.scanData.dateOfBirth.fieldValue }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content class="v-list-item__content_description">
                                                    Staatsangehörigkeit
                                                </v-list-item-content>
                                                <v-list-item-content class="v-list-item__content_value">
                                                    <div v-if="item.scanData.nationality">
                                                        {{ nationality(item.scanData.nationality.fieldValue) }}
                                                    </div>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-row class="mt-2" align="center" justify="center">
                    <div class="row-footer-spacer">
                        <span class="grey--text">Anzahl Dokumente pro Seite</span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn dark text class="ml-2 row-items-per-page" v-bind="attrs" v-on="on">
                                    {{ itemsPerPage }}
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                                             @click="updateItemsPerPage(number)">
                                    <v-list-item-title>{{ number }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="text-center row-footer-spacer">
                        <v-pagination v-model="page" :length=numberOfPages prev-icon="mdi-menu-left"
                                      next-icon="mdi-menu-right">
                        </v-pagination>
                    </div>
                    <div class="row-footer-spacer"></div>
                </v-row>
            </template>

        </v-data-iterator>
    </v-card>
</template>
<script>
export default {
    props: {
        items: Array,
    },
    watch: {
        page() {
            this.reloadImages()
        },
        itemsPerPage() {
            this.reloadImages()
        },
        items() {
            this.reloadImages()
        },
        onlyShow() {
            if (this.page != 1) {
                this.page = 1
            } else {
                this.reloadImages()
            }
        }
    },
    methods: {
        reloadImages() {
            this.$store.dispatch("loadVisibleImages", {
                itemsPerPage: this.itemsPerPage,
                page: this.page,
                status: this.onlyShow.technical
            });
        },
        nationality(item) {
            var found = this.$store.getters.getCountries.find(x => x.icao3 == item)
            return (found ? found.nationalitaet : '');
        },
        docType(item) {
            var found = this.$store.getters.getInstituteDocuments.find(x => x.ospDocType == item)
            return (found ? found.shortDesc : '');
        },
        triggerDeleteDialog(id) {
            this.scanToDeleteID = id
            this.deleteDialog = true
        },
        triggerRestoreDialog(id){
            this.scanToRestoreID = id
            this.restoreDialog = true
        },
        restoreScan(){
            this.$store.dispatch("restoreScan", this.scanToRestoreID).then(()=>{
                this.loadImages()
                this.restoreDialog = false
            })
        },
        triggerPickDocDialog(scan) {
            this.editScan = scan
            this.pickDocDialog = true
        },
        triggerActionDialog(scan) {
            this.$emit("editScan", scan.id)
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },
        checkValues(element, search) {
            for (var key in element) {
                if (typeof element[key] === "object") {
                    this.checkValues(element[key], search)
                } else {
                    if (element[key].toString().toLowerCase().includes(search.toString().toLowerCase())) {
                        return true
                    }
                }
            }
            return false
        },
        epochToDate(epoch) {
            var options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            };
            return new Date(epoch).toLocaleString("de-DE", options).replace(",", "");
        },
        getStatusText(key) {
            var found = this.status.find(x => x.technical.toLowerCase() == key.toLowerCase()).displayText
            return found
        },
        loadImages() {
            this.$store.dispatch("loadScans");
        },
        customSearch(items, search, filter) {
            search = search.toString().toLowerCase()
            return items.filter(i => (
                Object.keys(i).some(j => filter(i[j], search))
            ))
        },
        downloadPdf(scan) {
            this.$store.dispatch("downloadPdf", scan)
        },
        deleteScan() {
            //console.log("Delete:", this.scanToDeleteID)
            this.$store.dispatch("deleteScan", this.scanToDeleteID);
            this.deleteDialog = false
            this.reloadImages()
        },
    },
    computed: {
        countries() {
            return this.$store.getters.getCountries
        },
        fieldnames() {
            return this.$store.getters.getFieldnames
        },
        numberOfPages() {
            return Math.ceil(this.filteredItems.length / this.itemsPerPage)
        },
        filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        },
        filteredItems() {
            var onlyShow = this.onlyShow
            //console.log(onlyShow)
            var search = this.search
            var showItems = []
            this.items.forEach(element => {
                if (onlyShow.technical.toLowerCase() == element.status.toLowerCase() || onlyShow.technical
                    .toLowerCase() == "alle") {
                    showItems.push(element)
                }
            });
            var finalItems = showItems
            if (search != "") {
                finalItems = []
                showItems.forEach(element => {
                    if ((element.scanData.dateOfBirth && element.scanData.dateOfBirth.fieldValue.toString().toLowerCase()
                        .includes(search.toString()
                            .toLowerCase())) || (element.scanData.firstNames && element.scanData.firstNames.fieldValue
                        .toString().toLowerCase().includes(search
                            .toString().toLowerCase())) || (element.scanData.lastName && element.scanData.lastName.fieldValue
                        .toString().toLowerCase()
                        .includes(search.toString().toLowerCase())) || (element.scanData.nationality && this.nationality(
                        element.scanData.nationality
                            .fieldValue).toString().toLowerCase().includes(search.toString().toLowerCase())) || this.docType(
                        element.dtype.fieldValue).toString().toLowerCase().includes(search.toString().toLowerCase())) {
                        finalItems.push(element)
                    }
                });
            }
            return finalItems
        }
    },
    data() {
        return {
            deleteDialog: false,
            scanToDeleteID: null,
            scanToRestoreID:null,
            actionDialog: false,
            isCompany: false,
            pickDocDialog: false,
            editScan: "",
            search: "",
            showDialog: false,
            itemsPerPageArray: [5, 10, 25],
            filter: {},
            sortDesc: true,
            page: 1,
            restoreDialog: false,
            itemsPerPage: 5,
            onlyShow: {
                displayText: "Neu",
                technical: "Neu"
            },
            status: [{
                displayText: "Neu",
                technical: "Neu"
            },
                {
                    displayText: "Bearbeitet",
                    technical: "bearbeitet"
                },
                {
                    displayText: "Gelöscht",
                    technical: "geloescht"
                },
                {
                    displayText: "Alle",
                    technical: "alle"
                },
            ],
            keys: [
                'Name',
                'Dokumentenart',
                'Scanndatum',
                'Abteilung',
            ],
        }
    },
}
</script>
<style scoped>
.home-tab-outer-wrapper {
    max-height: 100%;
    background-color: #f0f0f0 !important;
    max-width: 930px;
    margin-right: auto;
    margin-left: auto;
    width: -webkit-fill-available;
}
</style>

<style>
.v-application--wrap {
    flex-grow: 1;
    min-height: 0;
    max-height: 100%;
}

.scan-data-iterator {
    min-height: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.scan-data-iterator .data-iterator-row {
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
}

.scan-data-iterator .mt-2 {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
}

.row-toolbar {
    max-height: 64px;
}
</style>
<template>
    <div class="text-center">
        <v-dialog v-model="show" width="500">
            <v-card>
                <v-card-title class="success-dialog-title">
                    {{ success[0] ? actionCatalog[success[0].action] : "" }}
                </v-card-title>

                <v-card-text v-if="highestClass < 4">
                    Der Prozess wurde erfolgreich ausgeführt.
                </v-card-text>

                <v-data-table v-if="highestClass > 3" :headers="headers" :items="success" item-key="code"
                              group-by="messageClass" class="elevation-1">
                    <template v-slot:group.header="{ group }">
                        <td :colspan="headers.length">
                            {{ buildGroupHeader(group) }}
                        </td>
                    </template>
                </v-data-table>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click.stop="ok">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            messageAmounts: {
                information: 0,
                warning: 0,
                error: 0,
                critError: 0,
            },
            headers: [{
                text: 'Nachricht',
                align: 'start',
                value: 'message',
                groupable: false,
            },
                {
                    text: 'Code',
                    value: 'code',
                    align: 'right'
                },
            ],
            highestClass: 4,
            actionCatalog: {
                "LEGITIMATION_ANLEGEN": "Legitimation anlegen"
            }
        }
    },
    props: {
        value: Boolean,
        success: null,
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                //console.log("I should be triggered")
                this.$emit('input', value)
            }
        },
    },
    methods: {
        ok() {
            this.$store.dispatch("changeActiveSuccess", false)
            this.show = false
        },
        buildGroupHeader(group) {
            var groupHeader = "Wrong code"
            switch (group) {
                case 0:
                    groupHeader = "Informationen: "
                    break
                case 2:
                    groupHeader = "Warnungen: "
                    break
                case 4:
                    groupHeader = "Fehler: "
                    break
                case 8:
                    groupHeader = "Kritische Fehler: "
                    break
                default:
                    break
            }

            var amount = 0

            this.success.forEach(function (message) {
                if (message.messageClass == group) {
                    amount++
                }
            });

            return groupHeader + amount
        }
    },
    watch: {
        show() {
            //console.log(this.success)
        },
        success() {
            //console.log(value)
            var highest = 0
            var that = this
            this.success.forEach(function (message) {
                if (message.messageClass > highest) {
                    highest = message.messageClass
                }
                //console.log("Highest Class: " + highest)
                that.highestClass = highest
            });
        }
    }
}
</script>

<style scoped>
.error-dialog-title {
    color: white;
    font-size: 26px;
}

.v-card__text {
    padding: 24px !important;
    font-size: 18px;
}

.success-dialog-title {
    background-color: #006400 !important;
    color: #fff;
}
</style>
<template>
    <div class="text-center">
        <v-dialog v-model="show" width="500">
            <v-card>
                <v-card-title class="primary error-dialog-title">
                    LegiScan_neo
                </v-card-title>

                <v-card-text v-if="!showTable">
                    {{ substitute(error.data) }} (Status: {{ error.status }})
                </v-card-text>

                <template v-if="showTable">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Feld
                                </th>
                                <th class="text-left">
                                    Fehler
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in error.data" :key="item.fieldname">
                                <th class="text-left">
                                    {{ fieldnames[item.fieldname] }}
                                </th>
                                <th class="text-left">
                                    {{ item.message }}
                                </th>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click.stop="ok">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableErrors: [961],
            fieldnames: {
                dateOfBirth: "Geburtsdatum",
                documentNumber: "Dokumentennummer",
                dtype: "Dokumentenart",
                everythingChecked: "Alles geprüft",
                firstNames: "Vornamen",
                id: "ID",
                issuedAt: "Ausstellungsdatum",
                issuer: "Ausstellende Behörde",
                issuerCountry: "Ausstellungsland",
                lastName: "Nachname",
                nationality: "Staatsangehörigkeit",
                placeOfBirth: "Geburtsort",
                validUntil: "Ablaufdatum",
            }
        }
    },
    props: {
        value: Boolean,
        error: null,
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        showTable() {
            if (this.error.status === 401) {
                //console.log("log log")
                this.$store.dispatch("logout")
                return false
            }
            return this.tableErrors.includes(this.error.status);
        }
    },
    methods: {
        substitute(data) {
            if (this.error.status === 951) {
                var msg = this.error.data.split(";")
                return `${msg[0]} (Dokumentart = ${this.docType(msg[1])}, Staatsangehörigkeit = ${this.nationality(msg[2])}, Ausstellerland = ${this.country(msg[3])})`
            }
            return data
        },
        ok() {
            this.show = false
        },
        nationality(item) {
            var found = this.$store.getters.getCountries.find(x => x.icao3 == item)
            return (found ? found.nationalitaet : '');
        },
        country(item) {
            var found = this.$store.getters.getCountries.find(x => x.icao3 == item)
            return (found ? found.kurzform : '');
        },
        docType(item) {
            var found = this.$store.getters.getInstituteDocuments.find(x => x.ospDocType == item)
            return (found ? found.shortDesc : '');
        },
    },
    watch: {
        show() {
            //console.log(this.error)
        },
        error(value) {
            console.log(value)
        }
    }
}
</script>

<style scoped>
.error-dialog-title {
    color: white;
    font-size: 26px;
}

.v-card__text {
    padding: 24px !important;
    font-size: 18px;
}
</style>
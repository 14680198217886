import axios from "axios"

const state = {
    loggedIn: false,
    loginPending: false,
    lastActivity: undefined,
}
const mutations = {
    setLoggedIn(state, loggedIn) {
        state.loggedIn = loggedIn;
        state.loginPending = false;
    },
    setLoginPending(state, penidingstate) {
        state.loginPending = penidingstate
    },
    setLastActivity(state, lastActivity) {
        state.lastActivity = lastActivity
    }
}
const actions = {
    async relogin({
                      commit
                  }) {
        if (localStorage.getItem("token")) {
            commit("setLoginPending", true);
            axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`
            try {
                await axios.get('/osplogin/me');
                //console.log("You are logged in now")
                commit("setLoggedIn", true);
            } catch (error) {
                commit("setLoggedIn", false);
                localStorage["token"] = null;
            }
        }
    },
    async logout({
                     commit, dispatch
                 }) {
        commit("setLoggedIn", false);
        commit("setToken", null)
        dispatch("clearScans")
    },
    async setLastActivity({
                              commit
                          }) {
        commit("setLastActivity", Date.now());
    },
}
const getters = {
    isUserLoggedin(state) {
        return state.loggedIn;
    },
    isLoginPending(state) {
        return state.loginPending;
    },
    getLastActivity(state) {
        return state.lastActivity;
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}
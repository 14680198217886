<template>
    <div v-if="!isCompany" class="step-one-details-item-wrapper">
        <div class="step-one-details-label-outer-wrapper">
            <div class="step-one-details-item-label-inner-wrapper">
                <label>{{ title }}</label>
            </div>
        </div>
        <div class="step-one-details-input-outer-wrapper-without-checkbox">
            <div class="step-one-details-item-inner-wrapper">
                <v-autocomplete clear-icon="mdi-close" clearable :append-icon="isRequired ? 'mdi-asterisk' : ''" v-model="computedValue" :items="items"
                                :item-text="itemText" :item-value="itemValue"
                                :return-object="false" hide-selected :label="label"></v-autocomplete>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {};
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    },
    props: {
        isRequired: {
            type: Boolean,
        },
        label: {
            type: String,
            required: true
        },
        isCompany: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        value: {
            required: true,
            default: null
        },
        itemText: {
            type: String,
            required: true
        },
        itemValue: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    },
}
</script>
<template>
    <div style="height: 100%">
        <v-dialog v-model="multipleNationalitiesDialog" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Mehrere Staatsangehörigkeiten
                </v-card-title>

                <v-card-text>
                    In OSPlus sind zwei Staatsangehörigkeiten hinterlegt. Bitte auf Aktualität
                    prüfen!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="multipleNationalitiesDialog = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card style="height:100%" class="mb-12 step-two-cardview-wrapper d-flex flex-column flex-grow-1"
                width="930px">
            <div style="height:92%" class="step-two-details-wrapper d-flex flex-column flex-grow-1 overflow-y-auto">
                <v-progress-linear indeterminate v-show="loading"></v-progress-linear>
                <template>
                    <v-data-table :headers="headers" :items="inputRows" :items-per-page="-1"
                                  class="elevation-1">
                        <template v-slot:item.scanValue="{item}">
                            <template v-if="rowsEqual(item) || item.label === 'Personen-Nr'">
                                {{ item.scanValue }}
                            </template>
                            <template v-else>
                                <v-chip
                                        color="orange"
                                        label
                                        outlined
                                        pill
                                > {{ item.scanValue }}
                                </v-chip>
                            </template>
                        </template>
                        <template v-slot:item.ospValue="{item}">
                            <template v-if="rowsEqual(item) || item.label === 'Personen-Nr'">
                                {{ item.ospValue }}
                            </template>
                            <template v-else>
                                <v-chip
                                        color="orange"
                                        label
                                        outlined
                                        pill
                                > {{ item.ospValue }}
                                </v-chip>
                            </template>
                        </template>
                    </v-data-table>
                </template>
            </div>
            <v-card-actions style="height: 8%">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="back">
                    Zurück
                </v-btn>
                <v-btn v-bind:disabled="!legitimizeEnabled" color="primary" :loading="legitimizeLoading"
                       @click="legitimize()">
                    Legitimationsdaten in OSPlus speichern und archivieren
                </v-btn>
            </v-card-actions>
        </v-card>

        <app-update-address-data :loading="legitimizeLoading" v-if="addressUpdateRequired" :show="addressUpdateRequired" :item="value" @updateAddressData="updateAddressData"
                                 @notUpdateAddressData="notUpdateAddressData"></app-update-address-data>
        <app-update-address-data :loading="legitimizeLoading" v-if="addressCreateRequired" :show="addressCreateRequired" :item="value" @updateAddressData="createAddressData"
                                 @notUpdateAddressData="notCreateAddressData" title="Keine Addressdaten im OSPLus gefunden" subtitle="Wollen Sie die Abweichung ins OSPlus übertragen?"></app-update-address-data>
        <app-update-first-names-dialog :loading="legitimizeLoading" v-if="firstNamesUpdateRequired" :firstName="value.scanData.firstNames.fieldValue"
                                       :show="firstNamesUpdateRequired" :item="value"
                                       @updateFirstNames="updateFirstNames"
                                       @notUpdateFirstNames="notUpdateFirstNames"></app-update-first-names-dialog>
        <app-update-birth-name-dialog :loading="legitimizeLoading" v-if="birthNameUpdateRequired" :show="birthNameUpdateRequired" :item="value" @updateBirthName="updateBirthName"
                                      @notUpdateBirthName="notUpdateBirthName"></app-update-birth-name-dialog>
    </div>
</template>

<script>
import appUpdateAddressData from "@/components/Scan/AddressDataUpdate.vue";
import appUpdateFirstNamesDialog from "@/components/Scan/UpdateFirstNamesDialog.vue";
import appUpdateBirthNameDialog from "@/components/Scan/UpdateBirthNameDialog.vue"
export default {
    components: {
        appUpdateAddressData,
        appUpdateFirstNamesDialog,
        appUpdateBirthNameDialog
    },
    props: {
        OspPerson: {
            type: Object,
        },
        addressUpdateRequired: {
            required: true,
            type: Boolean
        },
        addressCreateRequired: {
            required: true,
            type: Boolean
        },
        firstNamesUpdateRequired: {
            required: true,
            type: Boolean
        },
        birthNameUpdateRequired: {
            required: true,
            type: Boolean
        },
        value: {
            required: true,
            type: Object
        },
        loading: {
            required: true,
            type: Boolean
        },
        institute: {
            required: true,
            type: Object
        },
        legitimizeLoading: {
            required: true,
            type: Boolean
        },
        countries: {
            required: true,
            type: Array
        },
        init: {
            required: true,
        },
        displayAddressData: {
            required: true,
            type: Boolean
        },
        displayBirthName: {
            required: true,
            type: Boolean
        }
    },
    watch: {
        init(value) {
            if (value) {
                this.getPerson()
                this.initData()
            }

        },
        OspPerson() {
            this.displayThePerson()
        }
    },
    data() {
        return {
            personNumber: "",
            charMap: {
                "â": "a",
                "Â": "A",
                "ç": "c",
                "Ç": "C",
                "ê": "e",
                "Ê": "E",
                "ë": "e",
                "Ë": "E",
                "î": "i",
                "Î": "I",
                "ï": "i",
                "Ï": "I",
                "ô": "o",
                "Ô": "O",
                "û": "u",
                "Û": "U",
                "õ": "o",
                "Õ": "O",
                "ø": "o",
                "Ø": "O",
                "œ": "oe",
                "Œ": "OE",
                "å": "a",
                "Å": "A",
            },
            legitimizeEnabled: false,
            inputRows: [],
            multipleNationalitiesDialog: false,
            headers: [
                {
                    text: 'Bezeichnung',
                    align: 'start',
                    sortable: false,
                    value: 'label',
                },
                {
                    text: 'Daten auf Legitimationsdokument',
                    value: 'scanValue'
                },
                {
                    text: 'Daten aus OSPlus',
                    value: 'ospValue'
                },
            ],
            nameSuffixesToIgnore: ["JUN", "SEN", "JUN.", "SEN.", "JUNIOR", "SENIOR"]
        }
    },
    methods: {
        replaceCharacters(text) {
            let normalizedText = "";
            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                const normalizedChar = this.charMap[char] || char;
                normalizedText += normalizedChar;
            }
            return normalizedText.trim().toUpperCase();
        },
        removeSuffix(name) {
            if (this.institute.ignoreNameSuffix) {
                for (const suffix of this.nameSuffixesToIgnore) {
                    if (name.endsWith(` ${suffix.toUpperCase()}`)) {
                        return name.substring(0, name.toUpperCase().lastIndexOf(suffix.toUpperCase()));
                    }
                }
            }
            return name;
        },
        rowsEqual(item) {
            if (item.label === "Name") {
                return this.replaceCharacters(item.scanValue).trim() === this.removeSuffix(this.replaceCharacters(item.ospValue)).trim()
            }
            if (item.label === "Weitere Vornamen" && item.ospValue === "-" && item.scanValue === "") {
                return true
            }
            return this.replaceCharacters(item.scanValue) === this.replaceCharacters(item.ospValue)
        },
        notUpdateFirstNames() {
            this.$store.dispatch("notUpdateFirstNames")
        },
        updateFirstNames(data) {
            this.$store.dispatch("updateFirstNames", data).then(() => this.legitimize())
        },
        displayThePerson() {

            this.legitimizeEnabled = !!this.OspPerson.personNumber;
            this.personNumber = this.OspPerson.personNumber
            this.inputRows[0].ospValue = this.OspPerson.personNumber
            this.inputRows[1].ospValue = this.OspPerson.title
            this.inputRows[2].ospValue = this.OspPerson.lastName
            this.inputRows[3].ospValue = this.OspPerson.firstName
            this.inputRows[4].ospValue = this.OspPerson.additionalFirstNames
            this.inputRows[5].ospValue = this.OspPerson.birthday
            this.inputRows[6].ospValue = this.OspPerson.placeOfBirth
            this.inputRows[7].ospValue = this.countries.find(x => x.ospnat === this.OspPerson.nationality) ? this.countries.find(x =>
                x.ospnat === this.OspPerson.nationality).nationalitaet : ""
            this.inputRows[8].ospValue = this.countries.find(x => x.ospnat === this.OspPerson.otherNationality) ? this.countries
                .find(x =>
                    x.ospnat === this.OspPerson.otherNationality).nationalitaet : ""
            if (this.OspPerson.otherNationality.length > 0) {
                this.multipleNationalitiesDialog = true
            }
            if (this.displayBirthName) {
                this.inputRows[9].ospValue = this.OspPerson.birthName
            }
            if (this.displayAddressData) {
                try {
                    let offset = this.displayBirthName ? 1 : 0
                    this.inputRows[9 + offset].ospValue = this.OspPerson.address.location
                    this.inputRows[10 + offset].ospValue = this.OspPerson.address.street
                    this.inputRows[11 + offset].ospValue = this.OspPerson.address.houseNumber
                    this.inputRows[12 + offset].ospValue = this.OspPerson.address.postcode
                } catch {
                    console.log("addressdaten konnten nicht gesetzt werden.")
                }
            }


        },
        updateAddressData(data) {
            this.$store.dispatch("updateAddressData", data.id).then(() => this.legitimize())
        },
        notUpdateAddressData() {
            this.$store.dispatch("notUpdateAddressData")
        },
        createAddressData(data) {
            this.$store.dispatch("createAddressData", data.id).then(() => this.legitimize())
        },
        notCreateAddressData() {
            this.$store.dispatch("notCreateAddressData")
        },
        updateBirthName(data){
            this.$store.dispatch("updateBirthName",data.id).then(() => this.legitimize())
        },
        notUpdateBirthName() {
            this.$store.dispatch("notUpdateBirthName")
        },
        setPerson(personNumber) {
            this.$emit("setOspResource", {
                ospResourceID: personNumber,
                scanId: this.value.id
            })
        },
        getPerson() {
            this.$emit("loadOspPerson", this.value.id)
        },
        back() {
            this.$emit("back")
        },
        legitimize() {
            this.$emit("legitimize", this.value.id)

        },
        initData() {
            this.inputRows = [
                {
                    label: "Personen-Nr",
                    scanValue: "",
                    ospValue: "",
                },
                {
                    label: "Titel",
                    scanValue: "",
                    ospValue: "",
                },
                {
                    label: "Name",
                    scanValue: this.value.scanData.lastName.fieldValue,
                    ospValue: "",
                },
                {
                    label: "Vorname",
                    scanValue: this.value.scanData.firstNames.fieldValue,
                    ospValue: "",
                },
                {
                    label: "Weitere Vornamen",
                    scanValue: "",
                    ospValue: "",
                },
                {
                    label: "Geburtsdatum",
                    scanValue: this.value.scanData.dateOfBirth.fieldValue,
                    ospValue: "",
                },
                {
                    label: "Geburtsort",
                    scanValue: this.value.scanData.placeOfBirth.fieldValue,
                    ospValue: "",
                },
                {
                    label: "Staatsangehörigkeit",
                    scanValue: this.countries.find(x => x.icao3 === this.value.scanData.nationality.fieldValue).nationalitaet,
                    ospValue: "",
                },
                {
                    label: "2. Staatsangehörigkeit",
                    scanValue: "",
                    ospValue: "",
                },
            ];
            if (this.displayBirthName) {
                this.inputRows.push({
                    label: "Geburtsname",
                    scanValue: this.value.scanData.birthName.fieldValue,
                    ospValue: "",
                })
            }
            if (this.displayAddressData) {
                try {
                    this.inputRows.push({
                            label: "Ort",
                            scanValue: this.value.scanData.address.location.fieldValue,
                            ospValue: "",
                        },
                        {
                            label: "Straße",
                            scanValue: this.value.scanData.address.street.fieldValue,
                            ospValue: "",
                        },
                        {
                            label: "Hausnummer",
                            scanValue: this.value.scanData.address.houseNumber.fieldValue,
                            ospValue: "",
                        },
                        {
                            label: "PLZ",
                            scanValue: this.value.scanData.address.postcode.fieldValue,
                            ospValue: "",
                        })
                } catch (e) {
                    console.log(e)
                }
            }
        }
    },
    created() {
    }
}
</script>
<style scoped>
</style>
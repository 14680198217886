<template>
    <v-toolbar
            dense
            elevation="8"
            prominent
            rounded
            color="primary"
            width="100%"
    >
        <div class="main-header">
            <div class="main-header-img">
                <v-img v-bind:src="info.logo" max-height="96" contain>
                </v-img>
            </div>
            <div class="main-header-logout">
                <app-notification></app-notification>
            </div>
            <div class="main-header-language">
                <v-btn @click="logout" tile class="top-menu-buttons">
                    Abmelden
                    <v-icon right>
                        fas fa-sign-out-alt
                    </v-icon>
                </v-btn>
            </div>
        </div>
    </v-toolbar>
</template>
<script>
import NotificationComponent from "../Notification.vue"

export default {

    data() {
        return {}
    },
    computed: {
        info() {
            return this.$store.getters.getInfo
        }
    },
    methods: {
        logout() {
            this.$store.dispatch("logout")
        },
    },
    components: {
        appNotification: NotificationComponent
    }
}
</script>

<style scoped>
.main-header {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-bottom: 15px;
}
</style>
<template>
    <div v-if="!isCompany && value" class="step-one-details-item-wrapper">
        <div class="step-one-details-label-outer-wrapper">
            <div class="step-one-details-item-label-inner-wrapper">
                <label v-bind:for="'input-' + value.fieldValue">{{ title }}</label>
            </div>
        </div>
        <div class="step-one-details-input-outer-wrapper">
            <div class="step-one-details-item-inner-wrapper">
                <v-menu ref="birthdayMenu" v-model="birthdayMenu" :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-bind:disabled=value.checked v-model="value.fieldValue" :rules="dateRules"
                                      v-bind="attrs" v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker ref="birthdayPicker" v-model="date" @input="birthdayMenu = false">
                    </v-date-picker>
                </v-menu>
            </div>
        </div>
        <div class="step-one-details-checkbox-outer-wrapper">
            <div class="step-one-details-item-inner-wrapper">
                <v-checkbox v-model=value.checked :disabled="allowEmpty?false:(value.fieldValue.trim() === '' && !value.checked)"
                            class="step-one-checkbox" color="#ff0000" hide-details></v-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            birthdayMenu: false,
            dateRules: [
                v => v.length <= 10 || 'Datumsformat: DD.MM.YYYY',
                v => this.checkDate(v) || 'Das ist kein valides Datum',
            ],
            date: null
        }
    },
    props: {
        isCompany: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        allowEmpty:{
            type:Boolean,
            default: false
        }
    },
    methods: {
        createDate() {
            var today = (new Date()).toISOString().substr(0, 10)
            var startDate = new Date()
            if (this.checkDate(this.value.fieldValue)) {
                const [day, month, year] = this.value.fieldValue.split('.')
                startDate.setFullYear(year, month - 1, day)
                this.date = startDate.toISOString().substr(0, 10)
            } else {
                this.date = today
            }
        },
        checkDate(date) {
            var re =
                /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
            var match = date.match(re);
            if (match || date.trim() === '' || (date.length == 8 && /^\d+$/.test(date))) {
                return true
            }
            return false
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },
    },
    watch: {
        date(val) {
            this.value.fieldValue = this.formatDate(val)
        },
    },
    created() {
        this.createDate()
    }
};
</script>

<style scoped>
@import 'Style.css';
</style>
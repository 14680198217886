import axios from "axios";

const state = {
    globalNotifications: []
}
const mutations = {
    setGlobalNotifications(state, value) {
        state.globalNotifications = value;
    },

}
const actions = {
    async loadGlobalNotificationByInstitute({
                                                commit
                                            }, instituteId) {
        try {
            var globalNotificationResponse = (await axios.get(`globalNotification/institute/${instituteId}`)).data;

            commit("setGlobalNotifications", globalNotificationResponse);
        } catch (error) {
            console.log(error)
        }
    },
}
const getters = {
    getGlobalNotifications(state) {
        return state.globalNotifications;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}
<template>
    <div class="text-center">
        <v-dialog
                v-model="show"
                width="500"
        >
            <v-card>
                <v-card-title class="primary mini-dialog-title">
                    Keine Person gefunden
                </v-card-title>
                <v-card-text>
                    Es wurde keine passende Person in OSPlus gefunden. Bitte geben Sie eine Personen-Nr. ein oder
                    korrigieren Sie die Daten des Legitimationsdokuments auf der vorherigen Maske.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click.stop="ok">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            searchInput: "",
        }
    },
    props: {
        value: Boolean
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    }, methods: {
        ok() {
            this.show = false
        },
    }
}
</script>

<style scoped>
.not-found-item-wrapper {
    display: flex;
    justify-content: space-between;
}

.not-found-input, .not-found-input-outer-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.not-found-input {
    border: solid black 2px;
    border-radius: 4px;
}

.not-found-input-outer-wrapper {
    margin-left: 15px;
    margin-right: 15px;
}

.mini-dialog-title {
    color: white;
}
</style>
<template>
    <div class="text-center">
        <v-menu offset-y :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        @click="loadNotifications"
                >
                    <v-badge
                            v-if="userNotifications.length != 0"
                            color="green"
                            :content="userNotifications.length"
                    >
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>
                    <v-icon v-else>mdi-bell</v-icon>
                </v-btn>
            </template>
            <v-list v-if="userNotifications.length != 0">
                <v-list-item
                        v-for="(userNotification, index) in userNotifications"
                        :key="index"
                        expand
                >
                    <v-list-item-icon>
                        <v-btn text @click="markAsSeen(userNotification.id)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="wrap-text">{{ userNotification.subject }}</v-list-item-title>
                        <v-list-item-subtitle class="wrap-text">{{ userNotification.body }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="userNotification.link">
                        <a :href="userNotification.link">
                            <v-btn icon>
                                <v-icon color="blue">mdi-link</v-icon>
                            </v-btn>
                        </a>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn text @click="markAllAsSeen()">Alle als gelesen markieren</v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list v-else>
                <v-list-item>
                    <v-list-item-title>Keine Benachrichtigungen</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
export default {
    data: () => ({
        closeOnContentClick: false
    }),
    computed: {
        userNotifications() {
            return this.$store.getters.getUserNotifications;
        },
        userLoggedIn() {
            return this.$store.getters.isUserLoggedin;
        }
    },
    methods: {
        loadNotifications() {
            this.$store.dispatch("loadUserNotification")
        },
        markAsSeen(id) {
            this.$store.dispatch("setUserNotificationSeen", id)
        },
        markAllAsSeen() {
            this.$store.dispatch("setUserNotificationSeenAll")
        }
    },
    created() {
        var notificationIntervall = setInterval(() => {
            this.loadNotifications()
            if (!this.userLoggedIn) {
                clearInterval(notificationIntervall)
            }
        }, 15000);

    }
}
</script>
<style scoped>
.wrap-text {
    text-wrap: initial;
}
</style>
import axios from "axios";

const state = {
    osppersons: [],
    ospDisplayPerson: null,
    ospPersonLoading: false,
    legitimizeLoading: false,
    potentialCustomerLoading: false
}
const mutations = {
    setPotentialCustomerLoading(state, potentialCustomerLoading) {
        state.potentialCustomerLoading = potentialCustomerLoading
    },
    setOspPersonLoading(state, ospPersonLoading) {
        state.ospPersonLoading = ospPersonLoading
    },
    setLegitimizeLoading(state, legitimizeLoading) {
        state.legitimizeLoading = legitimizeLoading
    },
    setOspperson(state, persons) {
        state.osppersons = persons;
    },
    setDisplayOspperson(state, person) {
        state.ospDisplayPerson = person;
    },
    setAddressDataOfDisplayPerson(state, address) {
        const ospPerson = JSON.parse(JSON.stringify(state.ospDisplayPerson))
        ospPerson.address = address
        state.ospDisplayPerson = ospPerson
    },
    setBirthNameOfDisplayPerson(state, birthNameObj) {
        const ospPerson = JSON.parse(JSON.stringify(state.ospDisplayPerson))
        ospPerson.birthName = birthNameObj.birthName
        state.ospDisplayPerson = ospPerson
    },
    setFirstNameOfDisplayPerson(state, name) {
        const ospPerson = JSON.parse(JSON.stringify(state.ospDisplayPerson))
        ospPerson.firstName = name.firstName
        ospPerson.additionalFirstNames = name.additionalFirstNames
        state.ospDisplayPerson = ospPerson
    },

}
const actions = {
    async createPotentialCustomer({commit, dispatch}, data) {
        try {
            commit("setPotentialCustomerLoading", true)
            const scan = (await axios.post(`/scan/${data.id}/ospresource`, data.data)).data
            commit("setPotentialCustomerLoading", false)
            commit("setSelectedScanOspResource", scan.ospResource)
            commit("setSelectedScanOspResourcePotentialCustomer", scan.potentialCustomerCreated)
            return scan
        } catch (error) {
            commit("setPotentialCustomerLoading", false)
            dispatch("createError", error.response)
            throw error
        }
    },
    async updateFirstNames({
                               commit, dispatch
                           }, updateData) {
        try {
            commit("setLegitimizeLoading",true)
            const ospNames = await axios.put(`/scan/${updateData.scanID}/ospresource/firstnames`, updateData.data);
            commit("setFirstNameOfDisplayPerson", ospNames.data)
            commit("setFirstNameUpdateRequired", false)
            commit("setLegitimizeLoading",false)
        } catch (error) {
            dispatch("createError", error.response)
            commit("setLegitimizeLoading",false)
            throw error
        }
    },
    async notUpdateFirstNames({
                                  commit
                              }) {
        commit("setFirstNameUpdateRequired", false)
    },
    async updateAddressData({
                                commit, dispatch
                            }, scanID) {
        try {
            commit("setLegitimizeLoading",true)
            var ospAddress = await axios.put(`/scan/${scanID}/ospresource/address`)
            commit("setAddressDataOfDisplayPerson", ospAddress.data)
            commit("setAddressUpdateRequired", false)
            commit("setLegitimizeLoading",false)
        } catch (error) {
            commit("setLegitimizeLoading",false)
            dispatch("createError", error.response)
            throw error
        }
    },
    async createAddressData({
                                commit, dispatch
                            }, scanID) {
        try {
            commit("setLegitimizeLoading",true)
            var ospAddress = await axios.post(`/scan/${scanID}/ospresource/address`)
            commit("setAddressDataOfDisplayPerson", ospAddress.data)
            commit("setAddressUpdateRequired", false)
            commit("setLegitimizeLoading",false)
        } catch (error) {
            commit("setLegitimizeLoading",false)
            dispatch("createError", error.response)
            throw error
        }
    },
    async notUpdateAddressData({
                                   commit
                               }) {
        commit("setAddressUpdateRequired", false)
    },
    async notCreateAddressData({
                                   commit
                               }) {
        commit("setAddressCreateRequired", false)
    },
    async updateBirthName({
                                commit, dispatch
                            }, scanID) {
        try {
            commit("setLegitimizeLoading",true)
            var ospAddress = await axios.put(`/scan/${scanID}/ospresource/birthname`)
            commit("setBirthNameOfDisplayPerson", ospAddress.data)
            commit("setBirthNameUpdateRequired", false)
            commit("setLegitimizeLoading",false)
        } catch (error) {
            dispatch("createError", error.response)
            commit("setLegitimizeLoading",false)
            throw error
        }
    },
    async notUpdateBirthName({
                                   commit
                               }) {
        commit("setBirthNameUpdateRequired", false)
    },
    async setScanOspResource({
                                 commit
                             }, params) {
        try {
            const scan = (await axios.put(`/scan/${params.scanId}/ospresource`, {
                ospResourceID: params.ospResourceID
            })).data;
            commit("setSelectedScanOspResource", scan.ospResource)
        } catch (e) {
            console.log(e)
            throw e
        }


    },
    async loadOspperson({
                            commit
                        }, params) {
        try {
            commit("setOspPersonLoading", true)
            var osppersonResponse = (await axios.get(`osp/person`, {
                params: params
            })).data;
            commit("setOspPersonLoading", false)
            commit("setOspperson", osppersonResponse);
        } catch (error) {
            commit("setOspPersonLoading", false)
            //console.log(error)
        }
    },

    async loadDisplayOspperson({
                                   commit, dispatch
                               }, scanId) {
        try {
            commit("setOspPersonLoading", true)
            commit("setDisplayOspperson", {
                additionalFirstNames: "",
                birthday: "",
                firstName: "",
                lastName: "",
                nationality: "",
                address: {
                    location: "",
                    street: "",
                    postcode: "",
                    houseNumber: "",
                },
                otherNationality: "",
                personNumber: "",
                persNr: "",
                placeOfBirth: "",
                title: "",
                birthName:""
            });

            await axios.get(`/scan/${scanId}/ospresource`).then(function (response) {
                dispatch("changeActiveError", false)
                commit("setDisplayOspperson", response.data);
                commit("setOspPersonLoading", false)
            }).catch(err => {
                //console.log(err.response)
                commit("setOspPersonLoading", false)
                dispatch("createError", err.response)
            })

            //console.log("osppersonResponse:", osppersonResponse)
        } catch (error) {
            //console.log(error)
        }
    },
    async legitimize({dispatch, getters, commit}, scanId) {
        commit("setLegitimizeLoading", true)
        commit("setAddressUpdateRequired", false)
        await axios.post(`/scan/${scanId}/legitimize`).then(function (response) {
            dispatch("changeActiveError", false)

            dispatch("createSuccess", response.data.meldungen.meldungen)
            dispatch("loadScans");
            commit("setLegitimizeLoading", false)
        }).catch(err => {
            if (err.response.status === 993 && getters.getInfo.considerAddress.type === "COMPARE_UPDATE") {
                commit("setAddressUpdateRequired", true)
            } else if (err.response.status === 940 && getters.getInfo.setName === "UPDATE_OR_MINUS") {
                commit("setFirstNameUpdateRequired", true)
            }else if (err.response.status === 941 && getters.getInfo.captureBirthName === "COMPARE_UPDATE") {
                commit("setBirthNameUpdateRequired", true)
            }else if (err.response.status === 942 && getters.getInfo.considerAddress.type === "COMPARE_UPDATE") {
                commit("setAddressCreateRequired", true)
            }  else {
                dispatch("createError", err.response)
            }
            commit("setLegitimizeLoading", false)
            throw err
        })

    },
}
const getters = {
    getOspPersons(state) {
        return state.osppersons;
    },
    getDisplayOspperson(state) {
        return state.ospDisplayPerson;
    },
    getOspPersonLoading(state) {
        return state.ospPersonLoading
    },
    getLegitimizeLoading(state) {
        return state.legitimizeLoading
    },
    getPotentialCustomerLoading(state) {
        return state.potentialCustomerLoading
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}
<template>
    <div class="text-center">
        <v-dialog v-model="show" width="520">
            <v-card>
                <v-card-title class="primary mini-dialog-title">
                    Abweichende Vorname im OSPlus gefunden
                </v-card-title>
                <v-card-text>
                    <v-card-subtitle>
                        Wollen Sie die Abweichung ins OSPlus übertragen?
                    </v-card-subtitle>
                    <v-row>
                        <v-text-field v-model="firstNameString" label="Vorname" type="text"></v-text-field>
                        <v-text-field v-model="additionalFirstNamesString" label="Weitere Vornamen"
                                      type="text"></v-text-field>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="loading" color="primary" text @click.stop="yes">
                        Ja
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" color="primary" text @click.stop="no">
                        Nein
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstNameResponse: this.firstName,
            additionalFirstNamesResponse: ""
        }
    },
    props: {
        show: Boolean,
        item: Object,
        firstName: {
            required: true,
            type: String
        },
        loading:Boolean

    },
    computed: {
        firstNameString: {
            get() {
                return this.firstNameResponse
            },
            set(value) {
                this.firstNameResponse = value
            }
        },
        additionalFirstNamesString: {
            get() {
                return this.additionalFirstNamesResponse
            },
            set(value) {
                this.additionalFirstNamesResponse = value
            }
        },
    },
    methods: {
        yes() {
            this.$emit("updateFirstNames", {
                data: {
                    firstName: this.firstNameResponse,
                    additionalFirstNames: this.additionalFirstNamesResponse
                }, scanID: this.item.id
            })
        },
        no() {
            this.$emit("notUpdateFirstNames")
        },
    }
}
</script>

<style scoped>

.mini-dialog-title {
    color: white;
}
</style>
<style>
.chips-container {
    margin-bottom: 20px;
}
</style>
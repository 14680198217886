import axios from "axios";

const state = {
    countries: []
}
const mutations = {
    setCountries(state, countries) {
        state.countries = countries;
    }
}
const actions = {
    async loadCountries({
                            commit
                        }, params) {
        try {
            //console.log(axios.defaults.headers.common)
            var countryResponse = (await axios.get(`country`, {
                params: params
            })).data;

            var countries = []
            //console.log("countryResponse:", countryResponse)
            countryResponse.forEach(async (country) => {


                countries.push({
                    abkuerzung: country.abkuerzung,
                    created_at: country.created_at,
                    englisch: country.englisch,
                    icao2: country.icao2,
                    icao3: country.icao3,
                    id: country.id,
                    iso_3166_A2: country.iso_3166_A2,
                    iso_3166_A3: country.iso_3166_A3,
                    kfz: country.kfz,
                    kurzform: country.kurzform,
                    nationalitaet: country.nationalitaet,
                    osplap: country.osplap,
                    osplkz: country.osplkz,
                    ospnat: country.ospnat,
                    vollform: country.vollform,
                })
            })
            commit("setCountries", countries);
        } catch (error) {
            //console.log(error)
        }
    },
}
const getters = {
    getCountries(state) {
        return state.countries.sort((a, b) => (a.kurzform > b.kurzform) ? 1 : ((b.kurzform > a.kurzform) ? -1 : 0));
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}
<template>
    <div class="text-center">
        <v-dialog v-model="show" width="520">
            <v-card>
                <v-card-title class="primary mini-dialog-title">
                    {{title}}
                </v-card-title>
                <v-card-text>
                    <v-card-subtitle>
                        {{ subtitle }}
                    </v-card-subtitle>
                    <div v-if="item.scanData.address">
                        <v-icon>mdi-map-marker</v-icon>
                        {{ item.scanData.address.postcode.fieldValue }} {{ item.scanData.address.location.fieldValue }}
                        {{ item.scanData.address.street.fieldValue }}
                        {{ item.scanData.address.houseNumber.fieldValue }}
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn :loading="loading" color="primary" text @click.stop="yes">
                        Ja
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" color="primary" text @click.stop="no">
                        Nein
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        show: Boolean,
        item: Object,
        loading:Boolean,
        title:{
            type:String,
            default:"Abweichende Addressdaten im OSPLus gefunden"
        },
        subtitle:{
            type:String,
            default:"Wollen Sie die Abweichung ins OSPlus übertragen?"
        }
    },
    methods: {
        yes() {
            this.$emit("updateAddressData", this.item)
        },
        no() {
            this.$emit("notUpdateAddressData")
        }
    }
}
</script>

<style scoped>

.mini-dialog-title {
    color: white;
}
</style>
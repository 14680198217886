<template>
    <div v-if="!isCompany && value" class="step-one-details-item-wrapper">
        <div class="step-one-details-label-outer-wrapper">
            <div class="step-one-details-item-label-inner-wrapper">
                <label v-bind:for="'input-' + value.fieldValue">{{ title }}</label>
            </div>
        </div>
        <div class="step-one-details-input-outer-wrapper">
            <div class="step-one-details-item-inner-wrapper">
                <v-text-field class="step-one-details-input" v-bind:disabled=value.checked
                              :rules="inputRules" v-model=value.fieldValue
                              v-bind:id="'input-' + value.fieldValue" type="text"></v-text-field>
            </div>
        </div>
        <div class="step-one-details-checkbox-outer-wrapper">
            <div class="step-one-details-item-inner-wrapper">
                <v-checkbox v-model=value.checked
                            :disabled="allowEmpty?false:(value.fieldValue.trim() === '' && !value.checked)"
                            class="step-one-checkbox" color="#ff0000" hide-details></v-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        inputRules() {
            var rules = [
                v => v.length <= 100 || 'Maximal 100 Zeichen'
            ]
            if(!this.allowEmpty){
                rules.push(v => /[a-z0-9]/i.test(v) || "Mindestens ein Zeichen")
            }
            return rules
        }
    },
    props: {
        allowEmpty: {
            type: Boolean,
            default: false
        },
        isCompany: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },
};
</script>

<style scoped>
@import 'Style.css';
</style>
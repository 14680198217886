import axios from "axios";

const state = {
    svzs: []
}
const mutations = {
    setSvzs(state, value) {
        state.svzs = value;
    }
}
const actions = {
    async loadSvzs({getters, commit}) {
        try {
            const svzResponse = (await axios.get(`svz/institute/${getters.getInfo.id}`)).data;
            commit("setSvzs", svzResponse);
        } catch (error) {
            console.log(error)
        }
    },
}
const getters = {
    getSvzByType: (state) => (svzType) => {
        return state.svzs.filter(item => item.svzType === svzType);
    },
    getSvzs(state) {
        return state.svzs;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}
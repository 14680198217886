<template>
    <v-app id="inspire">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>Keine Institutsdaten verfügbar</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                Es tut uns Leid aber für diese Domain wurde kein Institut für LegiScan_neo registriert.
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click.stop="refresh">Aktualisieren</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        refresh() {
            this.$store.dispatch("loadInfo")
        },
    }
};
</script>

<style scoped>
.error-message {
    color: #f00
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import scan from '@/store/scan'
import institutInfo from '@/store/institutInfo'
import error from '@/store/error'
import success from '@/store/success'
import countries from '@/store/country'
import osplus from '@/store/osplus'
import login from '@/store/login'
import version from '@/store/version'
import globalNotification from "@/store/globalNotification"
import userNotification from "@/store/userNotification"
import svz from "@/store/svz";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        errorCode: null
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        setErrorCode(state, errorCode) {
            state.errorCode = errorCode
        }
    },
    actions: {
        async loginUser({commit}, params) {
            try {
                commit("setErrorCode", null)
                var token = (await axios.post(`osplogin`, params).catch(err => {
                    commit("setErrorCode", err.response.status)
                })).data.token;
                //console.log("I am the new token",token)
                commit("setToken", token)
            } catch (error) {
                //console.log(error)
            }
        },
        createInterceptor({dispatch}) {
            axios.interceptors.request.use(function (config) {
                if (config.url !== "/userNotification/me/notseen") {
                    dispatch("setLastActivity")
                }
                return config;
            }, function (error) {
                // Do something with request error
                return Promise.reject(error);
            });
        }
    },
    getters: {
        getToken(state) {
            return state.token
        },
        getErrorCode(state) {
            return state.errorCode
        }
    },
    modules: {
        scan,
        success,
        error,
        institutInfo,
        countries,
        osplus,
        login,
        version,
        globalNotification,
        userNotification,
        svz
    }
})

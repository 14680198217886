<template>
    <v-card style="display: flex; flex-direction: column;width: 100%;max-height: 92%;">
        <v-card-title>Personen zum Legitimationsdokument passend:</v-card-title>
        <v-row style="overflow: auto; flex-grow: 1; width: 100%">
            <template v-for="item in persons">
                <v-col cols="12" md="4" lg="3" sm="12" :key="item.personNumber">
                    <v-card class="mx-auto" max-width="344" outlined>
                        <v-card-title>
                            <v-app-bar
                                    flat
                                    color="primary"
                            >
                                <v-toolbar-title class="text-h6 white--text pl-0">
                                    {{ item.firstName }} {{ item.lastName }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-tooltip>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-badge
                                        v-on="on"
                                        v-bind="attrs"
                                        :color="getGrayScaleColor(item.hitProbability)"
                                        :content="item.hitProbability+'%'"
                                    >
                                    </v-badge>
                                </template>
                                <span>Trefferwahrscheinlichkeit des OSPlus</span>
                                </v-tooltip>

                            </v-app-bar>


                        </v-card-title>
                        <v-card-subtitle>
                            <v-icon>mdi-identifier</v-icon>
                            {{ item.personNumber }}
                        </v-card-subtitle>
                        <v-card-text>
                            <div>
                                <v-icon>mdi-cake</v-icon>
                                {{ item.birthday }}
                            </div>
                            <template v-if="isAddressData(item)">
                                <div>
                                    <v-icon>mdi-map-marker</v-icon>
                                    {{ item.address.street }} {{ item.address.houseNumber }},
                                    {{ item.address.location }} {{ item.address.postalCode }}
                                </div>
                            </template>
                            <template v-if="item.svz">
                                <v-icon>mdi-domain</v-icon>
                                {{ item.svz.shortDescription }} ({{ item.svz.shortDisplay }})
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn outlined rounded text color="primary" @click="setPerson(item)">
                                Auswählen
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-chip
                                    v-if="highlight === item.personNumber"
                                    color="green"
                                    outlined
                            >Aktuelle Auswahl
                            </v-chip>
                            <v-spacer></v-spacer>
                            <v-chip
                                    v-if="potentialCustomerAlreadyCreated === item.personNumber"
                                    color="green"
                                    outlined
                            >Erstellter Interesent
                            </v-chip>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </template>
            <v-col v-if="potentialCustomerEnabled" cols="12" md="4" lg="3" sm="12">
                <v-card v-if="!displayAddressData" class="mx-auto" max-width="344" outlined>
                    <v-card-title>
                        <v-app-bar
                            flat
                            color="primary"
                        >
                            <v-toolbar-title class="text-h6 white--text pl-0">
                                Adressdaten fehlen
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-app-bar>
                    </v-card-title>
                    <v-card-subtitle>
                        Adressdaten müssen erst erfasst werden
                    </v-card-subtitle>
                    <v-card-actions>
                        <v-btn outlined rounded text color="primary" @click="makeNewPotentialCustomer()">
                            Auswählen
                        </v-btn>
                    </v-card-actions>
                    <v-overlay
                        absolute
                        color="#111"
                    >
                        <v-btn  color="primary" x-large
                               @click="addAddressData()">
                            <div class="button-content">
                                <v-icon>mdi-arrow-left</v-icon>
                                <div class="button-text">Addressdaten erfassen</div>
                            </div>
                        </v-btn>
                    </v-overlay>
                </v-card>
                <v-card v-if="displayAddressData" :disabled="!displayAddressData" class="mx-auto" max-width="344" outlined>
                    <v-card-title>
                        <v-app-bar
                                flat
                                color="primary"
                        >
                            <v-toolbar-title class="text-h6 white--text pl-0">
                                {{ scan.scanData.firstNames.fieldValue }} {{
                                scan.scanData.lastName.fieldValue
                                }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-app-bar>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-icon>mdi-identifier</v-icon>
                        xxxxxxxxxxx
                    </v-card-subtitle>
                    <v-card-text>
                        <div>
                            <v-icon>mdi-cake</v-icon>
                            {{ scan.scanData.dateOfBirth.fieldValue }}
                        </div>
                        <div>
                            <v-icon>mdi-map-marker</v-icon>
                            {{ scan.scanData.address.street.fieldValue }}
                            {{ scan.scanData.address.houseNumber.fieldValue }},
                            {{ scan.scanData.address.location.fieldValue }}
                            {{ scan.scanData.address.postcode.fieldValue }}

                        </div>
                        <v-icon>mdi-domain</v-icon>
                        xxxxxxxx (xxxx)
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outlined rounded text color="primary" @click="makeNewPotentialCustomer()">
                            Auswählen
                        </v-btn>
                    </v-card-actions>
                    <v-overlay
                            absolute
                            color="#111"
                    >
                        <v-btn color="primary" :disabled="potentialCustomerAlreadyCreated != null" x-large
                               @click="makeNewPotentialCustomer()">
                            <div class="button-content">
                                <v-icon>mdi-plus</v-icon>
                                <div class="button-text">Interesent erstellen</div>
                            </div>
                        </v-btn>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        persons: {
            required: true,
            type: Array
        },
        highlight: {
            type: String
        },
        potentialCustomerEnabled: {
            required: true,
            type: Boolean
        },
        potentialCustomerAlreadyCreated: {
            type: String
        },
        scan: {
            required: true,
            type: Object
        },
        displayAddressData: {
            required:true,
            type:Boolean
        },
    },
    methods: {
        addAddressData(){
          this.$emit("addAddressData")
        },
        makeNewPotentialCustomer() {
            this.$emit("makeNewPotentialCustomer")
        },
        setPerson(person) {
            this.$emit("setPerson", person.personNumber)
            this.show = false
        },
        isAddressData(item) {
            if (item) {
                return item.address.street || item.address.houseNumber || item.address.location || item.address.postalCode
            }
            return false
        },
        getGrayScaleColor(value) {
            const startValue = 100;
            const endValue = 10;

            // Bereich prüfen, um sicherzustellen, dass der Wert im erwarteten Bereich liegt
            if (value < endValue || value > startValue) {
                throw new Error(`Der Wert muss zwischen ${endValue} und ${startValue} liegen.`);
            }

            const grayValue = Math.round((startValue - value) * 255 / (startValue - endValue));
            const hexValue = grayValue.toString(16).padStart(2, '0'); // Konvertierung in Hex-Wert
            return `#${hexValue}${hexValue}${hexValue}`; // Erzeugung des Hex-Werts für Grau

        }
    }
}
</script>

<style scoped>
.not-found-item-wrapper {
    display: flex;
    justify-content: space-between;
}

.not-found-label-outer-wrapper,
.not-found-input-outer-wrapper,
.not-found-button-outer-wrapper,
.not-found-birth-outer-wrapper {
    width: 25%;
}

.not-found-input-outer-wrapper {
    display: flex;
    justify-content: center;
}

.not-found-button-outer-wrapper {
    display: flex;
    justify-content: flex-end;
}

.mini-dialog-title {
    color: white;
}

.flex-dialog {
    display: flex !important;
}

.button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.button-text {
    margin-top: 5px;
}
</style>
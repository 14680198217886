<template>
  <v-stepper v-model="step" class="d-flex flex-column flex-grow-1">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
            >
              Abgleich mit Legitimationsdokument
            </div>
          </template>
          <span>Diese Daten dienen als Grundlage für alle Folgeschritte.</span>
        </v-tooltip>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="step > 2" step="2">
        <template v-if="potentialCustomerEnabled && displayAddressData">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                  v-bind="attrs"
                  v-on="on"
              >
                Person Suchen/Interresent erstellen
              </div>
            </template>
            <span>Dieser Schritt wird beim ersten mal Übersprungen wenn eine Person gefunden wurde.</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                  v-bind="attrs"
                  v-on="on"
              >
                Person Suchen
              </div>
            </template>
            <span>Dieser Schritt wird beim ersten mal Übersprungen wenn eine Person gefunden wurde.</span>
          </v-tooltip>
        </template>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="step > 3" step="3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
            >
              Abgleich mit OSP
            </div>
          </template>
          <span>Hier wird die Legitimation im OSPlus angelegt.</span>
        </v-tooltip>
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items class="d-flex flex-column flex-grow-1">
      <v-stepper-content step="1" style="height:100%" :class="{displayNone:(step !== 1)}"
                         class="d-flex flex-column flex-grow-1">
        <app-check-scan-data @save="saveScan"
                             @close="close" :documents="documents" v-model="scan" :isCompany="isCompany"
                             :continue-enabled="continueEnabled" :countries="countries"
                             :scroll-to-address-data="scrollToAddressData"
                             :institute="institute" :displayAddressData="displayAddressData"
                             :displayAddressDataCriteriaMatch="displayAddressDataCriteriaMatch"></app-check-scan-data>
      </v-stepper-content>

      <v-stepper-content step="2" :class="{displayNone:(step !== 2)}" style="height:100%"
                         class="d-flex flex-column flex-grow-1">
        <app-person-search @loadSvzs="loadSvzs" :svzs="svzs" :is-company="isCompany"
                           :potentialCustomerDialogLoad="potentialCustomerLoading" :OspPersons="OspPersons"
                           v-model="scan" style="display: flex"
                           :search="step === 2" :persons="OspPersons" @backScrollToAddress="backScrollToAddress"
                           @back="step = 1" :institute="institute"
                           @setOspResource="setOspResource" @next="step = 3"
                           :displayAddressData="displayAddressData"></app-person-search>
      </v-stepper-content>

        <v-stepper-content step="3" style="height:100%"
                           :class="{displayNone:(step !== 3)}" class="d-flex flex-column flex-grow-1">
            <app-legitimize-data :firstNamesUpdateRequired="firstNamesUpdateRequired"
                                 :birthNameUpdateRequired="birthNameUpdateRequired"
                                 @legitimize="legitimize"
                                 :OspPerson="OspPerson"
                                 :addressUpdateRequired="addressUpdateRequired" @loadOspPerson="loadOspPerson"
                                 :OspPersons="OspPersons" @back="step = 2" :countries="countries"
                                 :init="step === 3" :legitimizeLoading="LegitimizeLoading" v-model="scan"
                                 :loading="OspPersonLoading" :addressCreateRequired="addressCreateRequired"
                                 @setOspResource="setOspResource"
                                 :displayBirthName="institute.captureBirthName !== 'DISABLED'"
                                 :institute="institute" :displayAddressData="displayAddressData"></app-legitimize-data>
        </v-stepper-content>
    </v-stepper-items>
    <FullscreenDialog @close="close" title="OSPlus Daten" :dialog="showOspPopup">
      <v-container>
        <v-col md="12">
          <h1 style="text-align: center">Folgende Daten würden jetzt im OSPlus zu finden sein:</h1>
        </v-col>
        <v-col>
          <div class="d-flex flex-column justify-space-betwesen align-center">
            <v-img src="/static/OSPMock.png" width="100%" :aspect-ratio="16/9"></v-img>
          </div>
        </v-col>
        <v-col>
          <v-btn @click="showPdfPopup = true"><v-icon style="color: red;font-size: 30px;">mdi-file-pdf-outline
          </v-icon> Legitimationsdokument anzeigen</v-btn>
        </v-col>
        <FullscreenDialog @close="showPdfPopup = false" title="PDF Daten" :dialog="showPdfPopup">
          <v-container>
            <v-col md="12">
              <h1 style="text-align: center">Folgende Daten würden jetzt auf dem ZDA Dokument zu finden sein:</h1>
            </v-col>
            <v-col>
              <div class="d-flex flex-column justify-space-betwesen align-center">
                <v-img src="/static/PDFMock.png" width="100%" :aspect-ratio="16/9"></v-img>
              </div>
<!--              <v-btn @click="downloadPdf(scan)">
                <v-icon style="color: red;font-size: 30px;">mdi-file-pdf-outline
                </v-icon>
                Legitimationsdokument herunterladen
              </v-btn>-->
            </v-col>
          </v-container>
        </FullscreenDialog>
      </v-container>
    </FullscreenDialog>
  </v-stepper>
</template>
<script>
import CheckScanData from "@/components/Scan/ScanEdit/CheckScanData.vue";
import LegitimizeData from "@/components/Scan/ScanEdit/LegitimizeData.vue";
import PersonSearch from "@/components/Scan/ScanEdit/PersonSearch.vue";
import FullscreenDialog from "@/components/Common/FullscreenDialog.vue";

export default {
  props: {
    scan: {
      required: true,
      type: Object
    },
    countries: {
      required: true,
      type: Array
    },
    isCompany: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      showOspPopup: false,
      showPdfPopup:false,
      step: 1,
      scrollToAddressData: false,
      continueEnabled: true,
      createPotentialCustomerClicked: false
    }
  },
  computed: {
    displayAddressData() {
      return this.institute.considerAddress.type !== "DISABLED" && (this.scan.scanData.manualAddressData || this.institute.considerAddress.criteria.some(criteria => (criteria.docType === this.scan.scanData.dtype.fieldValue || criteria.docType == null) && (criteria.issuerCountry === this.scan.scanData.issuerCountry.fieldValue || criteria.issuerCountry == null)))
    },
    displayAddressDataCriteriaMatch() {
      return this.institute.considerAddress.type !== "DISABLED" && !this.institute.considerAddress.criteria.some(criteria => (criteria.docType === this.scan.scanData.dtype.fieldValue || criteria.docType == null) && (criteria.issuerCountry === this.scan.scanData.issuerCountry.fieldValue || criteria.issuerCountry == null))
    },
    potentialCustomerLoading() {
      return this.$store.getters.getPotentialCustomerLoading
    },
    svzs() {
      return this.$store.getters.getSvzs
    },
    potentialCustomerEnabled() {
      return this.institute.potentialCustomerCreate
    },
    addressUpdateRequired() {
      return this.$store.getters.getAddressUpdateRequired && this.scan != null
    },
      addressCreateRequired() {
          return this.$store.getters.getAddressCreateRequired && this.scan != null
      },
    firstNamesUpdateRequired() {
      return this.$store.getters.getFirstNamesUpdateRequired && this.scan != null
    },
      birthNameUpdateRequired(){
          return this.$store.getters.getBirthNameUpdateRequired && this.scan != null
      },
    OspPerson() {
      return this.$store.getters.getDisplayOspperson
    },
    OspPersons() {
      return this.$store.getters.getOspPersons
    },
    LegitimizeLoading() {
      return this.$store.getters.getLegitimizeLoading
    },
    OspPersonLoading() {
      return this.$store.getters.getOspPersonLoading
    },
    institute() {
      return this.$store.getters.getInfo
    },
    documents() {
      const docs = this.$store.getters.getInstituteDocuments;
      const filteredDocs = [];
      if (this.scan.scanData && this.scan.scanData.dtype && this.scan.scanData.dtype.fieldValue) {
        const persontype = this.$store.getters.getInstituteDocuments.find(x => x.ospDocType === this.scan.scanData.dtype
            .fieldValue)
            .personType;
        docs.forEach(element => {
          if (element.personType === persontype) {
            filteredDocs.push(element)
          }
        });
      }
      return filteredDocs
    },
  },
  components: {
    FullscreenDialog,
    appCheckScanData: CheckScanData,
    appLegitimizeData: LegitimizeData,
    appPersonSearch: PersonSearch
  },
  methods: {
    downloadPdf(scan) {
      this.$store.dispatch("downloadPdf", scan)
    },
    backScrollToAddress() {
      this.step = 1
      this.scrollToAddressData = true
    },
    loadSvzs() {
      this.$store.dispatch("loadSvzs")
    },
    async legitimize(scanId) {
      try {
        await this.$store.dispatch("legitimize", scanId)
        this.showOspPopup = true
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.$emit("close")
    },

    async loadOspPerson(scanId) {
      await this.$store.dispatch("loadDisplayOspperson", scanId);
    },
    async setOspResource(params) {
      await this.$store.dispatch("setScanOspResource", params);
      this.step = 3
    },
    async saveScanData(scanObject) {
      await this.$store.dispatch("editScan", scanObject)
    },
    saveScan(scanObject) {
      this.continueEnabled = false
      this.saveScanData(scanObject).then(() => {
        this.continueEnabled = true
        this.step = 2
      }).catch(() => {
        this.continueEnabled = true
      })
    }
  }
}
</script>

<style scoped>
.displayNone {
  display: none !important;
}
</style>
<template>
    <v-card class="mb-12 step-one-holder-wrapper d-flex flex-column flex-grow-1 test" height="100%">
        <v-row style="height:92%" class="d-flex flex-grow-1">
            <v-col style="max-height: 100%" col="12" md="5" xs="12" class="overflow-y-auto">
                <div class="step-one-column-one-wrapper">
                    <div v-if="value.scanData" class="step-one-details-wrapper">
                        <div class="step-one-details-item-wrapper"
                             style="border-bottom:2px solid #666; margin-bottom:15px">
                            <div class="step-one-details-label-outer-wrapper">
                                <div class="step-one-details-item-label-inner-wrapper">
                                    <label>Bezeichnung</label>
                                </div>
                            </div>
                            <div class="step-one-details-label-outer-wrapper">
                                <div class="step-one-details-item-label-inner-wrapper">
                                    <label>Wert</label>
                                </div>
                            </div>
                            <div class="step-one-details-checkbox-outer-wrapper">
                                <div class="step-one-details-item-inner-wrapper">
                                    <label>Geprüft</label>
                                </div>
                            </div>
                        </div>
                        <app-drop-down label="Dokumentenart" title="Art der Legitimation"
                                       :value="value.scanData.dtype"
                                       :items="documents" item-text="shortDesc"
                                       item-value="ospDocType"></app-drop-down>
                        <app-string title="Nummer" :isCompany="isCompany"
                                    :value="value.scanData.documentNumber">
                        </app-string>
                        <app-string title="Vorname(n)" :isCompany="isCompany"
                                    :value="value.scanData.firstNames">
                        </app-string>
                        <app-string title="Nachname" :isCompany="isCompany"
                                    :value="value.scanData.lastName">
                        </app-string>
                        <app-string
                            v-if="institute.captureBirthName !== 'DISABLED'"
                            title="Geburtsname"
                            allowEmpty
                            :value="value.scanData.birthName"></app-string>
                        <app-date title="Geburtsdatum" :isCompany="isCompany"
                                  :value="value.scanData.dateOfBirth">
                        </app-date>
                        <app-string title="Geburtsort" :isCompany="isCompany"
                                    :value="value.scanData.placeOfBirth">
                        </app-string>
                        <app-drop-down label="Nationalität" title="Staatsangehörigkeit"
                                       :value="value.scanData.nationality"
                                       :items="countries" item-text="kurzform"
                                       item-value="icao3">
                        </app-drop-down>
                        <app-string title="Ausstellende Behörde"
                                    :value="value.scanData.issuer"></app-string>
                        <app-drop-down label="Ausstellungsland"
                                       title="Ausstellende Behörde (Land)"
                                       :value="value.scanData.issuerCountry" :items="countries"
                                       item-text="kurzform"
                                       item-value="icao3"></app-drop-down>
                        <app-date title="Ausstellungsdatum"
                                  :value="value.scanData.issuedAt"></app-date>
                        <app-date
                                v-if="institute.captureValidUntil !== 'DISABLED'"
                                title="Ablaufdatum"
                                :allowEmpty="institute.captureValidUntil === 'OPTIONAL'"
                                :value="value.scanData.validUntil"></app-date>
                        <template v-if="displayAddressData">
                            <app-string v-if="value.scanData.address" title="PLZ"
                                        :isCompany="isCompany"
                                        :value="value.scanData.address.postcode"></app-string>
                            <app-string v-if="value.scanData.address" title="Wohnort"
                                        :isCompany="isCompany"
                                        :value="value.scanData.address.location"></app-string>
                            <app-string v-if="value.scanData.address" title="Straße"
                                        :isCompany="isCompany"
                                        :value="value.scanData.address.street"></app-string>
                            <app-string v-if="value.scanData.address" title="Hausnummer"
                                        :isCompany="isCompany"
                                        :value="value.scanData.address.houseNumber"></app-string>
                            <template v-if="displayAddressDataCriteriaMatch">
                                <v-btn block @click="setManualAddress(false)">Addressdaten nicht erfassen</v-btn>

                            </template>
                        </template>
                        <template v-if="!displayAddressData && displayAddressDataCriteriaMatch">
                            <v-btn block @click="setManualAddress(true)">Addressdaten manuell erfassen</v-btn>
                        </template>
                        <div ref="AddressScrollHolder"></div>
                    </div>
                </div>
            </v-col>
            <v-col style="max-height: 100%" class="overflow-y-auto" col="12" md="7" xs="12">
                <div v-if="value.security && value.security.securityStatus !== ''"
                     class="step-one-column-one-wrapper">
                    <div class="step-one-details-wrapper"
                         :style="securityTextStyle(value.security.securityStatus)">
                        <div class="step-one-details-item-wrapper">
                            <div style="width:100%"
                                 class="step-one-details-input-outer-wrapper">
                                <div v-if="value.security.securityStatus"
                                     class="step-one-details-item-inner-wrapper">
                                    <label
                                            v-bind:for="'input-' + value.security.securityStatus + '-hint'">{{
                                        generateSecurityText(value.security.securityStatus)
                                        }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-one-column-two-wrapper">
                    <v-container style="display:flex; justify-content:center">
                        <v-slide-group class="pa-4" active-class="success" show-arrows
                                       center-active>
                            <v-slide-item @click="activeSlide = slide"
                                          v-for="slide in value.images" :key="slide.id">
                                <v-card @click="activeSlide = slide" style="display:flex"
                                        class="ma-4" height="auto"
                                        width="100">
                                    <v-img v-bind:src="slide.processedImage"
                                           style="margin-top: auto; margin-bottom: auto;"
                                           class="grey lighten-2">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center"
                                                   justify="center">
                                                <v-progress-circular indeterminate
                                                                     color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>

                            </v-slide-item>
                        </v-slide-group>

                    </v-container>
                    <v-container style="display:flex; justify-content:center">
                        <img v-if="activeSlide" @click.stop="imageEditor = true"
                             v-bind:src="activeSlide.processedImage"
                             alt="">
                    </v-container>
                </div>
            </v-col>
        </v-row>
        <v-card-actions style="height: 8%" class="d-flex flex-grow-1">
            <v-spacer></v-spacer>

            <v-btn color="primary" @click="close">
                Schließen
            </v-btn>

            <v-btn color="primary" @click="save()" v-bind:disabled=isDisabled
                   :loading="!continueEnabled">
                Weiter
            </v-btn>
        </v-card-actions>
        <ImageEditor @saved="imageSaved($event)" style="display:none" v-if="activeSlide" v-model="imageEditor"
                     :source="activeSlide.baseImage" ref="tuiImageEditor"/>
    </v-card>
</template>

<script>
import appDate from "@/components/Scan/ScanEdit/InputComponents/DateComponent.vue";
import appString from "@/components/Scan/ScanEdit/InputComponents/StringComponent.vue";
import appDropDown from "@/components/Scan/ScanEdit/InputComponents/DropdownComponent.vue";
import ImageEditor from "@/components/Scan/ImageEditor.vue";

export default {
    props: {
        value: {
            required: true,
            type: Object
        },
        documents: {
            required: true,
            type: Array
        },
        isCompany: {
            required: true,
            type: Boolean
        },
        countries: {
            required: true,
            type: Array
        },
        institute: {
            required: true,
            type: Object
        },
        continueEnabled: {
            required: true,
            type: Boolean
        },
        displayAddressData: {
            required: true,
            type: Boolean
        },
        displayAddressDataCriteriaMatch: {
            required: true,
            type: Boolean
        },
    },
    data() {
        return {
            imageEditor: false,
            securityStatusTexts: {
                GENUINE: {
                    text: "Das Dokument konnte erfolgreich verifiziert werden",
                    color: "#008000"
                },
                ERROR: {
                    text: "Die Sicherheitsprüfungen konnten nicht erfolgreich durchgeführt werden. Bitte prüfen Sie das Dokument sorgfältig, bevor Sie die Legitimation vornehmen.",
                    color: "#aa0000"
                },
                FAILURE: {
                    text: "Die Sicherheitsprüfungen konnten nicht erfolgreich durchgeführt werden. Bitte prüfen Sie das Dokument sorgfältig, bevor Sie die Legitimation vornehmen.",
                    color: "#aa0000"
                },
                WARNING: {
                    text: "Einige Sicherheitsprüfungen konnten nicht erfolgreich durchgeführt werden. Bitte prüfen Sie das Dokument sorgfältig, bevor Sie die Legitimation vornehmen.",
                    color: "#aa0000"
                },
                ADMIN: {
                    text: "Das Dokument wurde manuell von einem Admin freigegeben obwohl einige Sicherheitsprüfungen nicht erfolgreich durchgeführt wurden.",
                    color: "#ff6600"
                },
                UNKNOWN: {
                    text: "Das Dokument ist unbekannt. Bitte prüfen Sie das Legitimationsdokument sorgfältig, bevor Sie die Legitimation vornehmen",
                    color: "#00aa00"
                },
                EXPIRED: {
                    text: "Das Dokument ist abgelaufen. Es kann nicht zur Legitimation verwendet werden.",
                    color: "#ff0000"
                },
                SAMPLE: {
                    text: "Dies ist ein Testdokument und kann daher nicht zur Legitimation verwendet werden.",
                    color: "#ff0000"
                },
            },
            activeSlide: null,
        }
    },
    watch: {
        value(value) {
            this.trimAllTexts(value)
            this.activeSlide = null
            value.images.sort((a, b) => (parseInt(a.createdAt) > parseInt(b.createdAt)) ? 1 : ((parseInt(b.createdAt) >
                parseInt(a.createdAt)) ? -1 : 0))
            this.checkIfNationalityExists(value)

        },
        manualAddressData(value) {
            if (value) {
                this.$nextTick(() => {
                    let self = this
                    self.$refs.AddressScrollHolder.scrollIntoView({
                        behavior: "smooth",
                        block: 'nearest',
                        inline: 'start'
                    })
                });
            }
        }
    },
    methods: {
        setManualAddress(value) {
            this.value.scanData.manualAddressData = value
        },
        trimAllTexts(scan) {
            scan.scanData.dtype.fieldValue = scan.scanData.dtype.fieldValue.trim()
            scan.scanData.documentNumber.fieldValue = scan.scanData.documentNumber.fieldValue.trim()
            scan.scanData.firstNames.fieldValue = scan.scanData.firstNames.fieldValue.trim()
            scan.scanData.lastName.fieldValue = scan.scanData.lastName.fieldValue.trim()
            scan.scanData.dateOfBirth.fieldValue = scan.scanData.dateOfBirth.fieldValue.trim()
            scan.scanData.placeOfBirth.fieldValue = scan.scanData.placeOfBirth.fieldValue.trim()
            scan.scanData.nationality.fieldValue = scan.scanData.nationality.fieldValue.trim()
            scan.scanData.issuer.fieldValue = scan.scanData.issuer.fieldValue.trim()
            scan.scanData.issuerCountry.fieldValue = scan.scanData.issuerCountry.fieldValue.trim()
            scan.scanData.issuedAt.fieldValue = scan.scanData.issuedAt.fieldValue.trim()
            scan.scanData.validUntil.fieldValue = scan.scanData.validUntil.fieldValue.trim()
            scan.scanData.address.location.fieldValue = scan.scanData.address.location.fieldValue.trim()
            scan.scanData.address.postcode.fieldValue = scan.scanData.address.postcode.fieldValue.trim()
            scan.scanData.address.street.fieldValue = scan.scanData.address.street.fieldValue.trim()
            scan.scanData.address.houseNumber.fieldValue = scan.scanData.address.houseNumber.fieldValue.trim()
        },
        createPotentialCustomer() {
            this.$emit("createPotentialCustomer", {
                scan: this.value,
                imagesChanged: this.value.imagesChanged
            })
        },
        checkIfNationalityExists(value) {
            if (!this.countries.find(x => x.icao3 === value.scanData.nationality.fieldValue)) {
                value.scanData.nationality.fieldValue = ""
                value.scanData.nationality.checked = false
            }
            if (!this.countries.find(x => x.icao3 === value.scanData.issuerCountry.fieldValue)) {
                value.scanData.issuerCountry.fieldValue = ""
                value.scanData.issuerCountry.checked = false
            }
        },
        imageSaved(event) {
            this.value.imagesChanged = true
            this.activeSlide.processedImage = event
        },
        save() {
            this.value.scanData.dateOfBirth.fieldValue = this.autoformatDate(this.value.scanData.dateOfBirth.fieldValue)
            this.value.scanData.validUntil.fieldValue = this.autoformatDate(this.value.scanData.validUntil.fieldValue)
            this.value.scanData.issuedAt.fieldValue = this.autoformatDate(this.value.scanData.issuedAt.fieldValue)
            this.personNumber = ""
            this.$emit("input", this.value)
            this.$emit("save", {
                scan: this.value,
                imagesChanged: this.value.imagesChanged
            })
        },
        autoformatDate(date) {
            if (date.length === 8 && !date.includes(".")) {
                date = date.substring(0, 2) + "." + date.substring(2, 4) + "." + date.substring(4, 8)
            }
            return date
        },
        close() {
            this.$emit("close")
        },
        securityTextStyle(statusText) {
            return "box-shadow: 0 0 0.4em" + (this.securityStatusTexts[statusText] ? this.securityStatusTexts[statusText]
                .color : "black")
        },
        generateSecurityText(key) {
            if (!this.securityStatusTexts[key]) {
                return ""
            } else {
                return this.securityStatusTexts[key].text
            }
        },
    },
    components: {ImageEditor, appDropDown, appString, appDate},
    computed: {
        manualAddressData() {
            return this.value.scanData.manualAddressData;
        },
        isDisabled() {
           for (const dataField in this.value.scanData) {
                if (dataField !== "address" && dataField !== "birthName" && dataField !== "validUntil" && Object.prototype.hasOwnProperty.call(this.value.scanData[dataField], "checked") && !this.value.scanData[dataField].checked) {
                    return true
                }
                if (dataField === "birthName" && this.institute.captureBirthName !== 'DISABLED' && Object.prototype.hasOwnProperty.call(this.value.scanData[dataField], "checked") && !this.value.scanData[dataField].checked) {
                    return true
                }
                if(dataField === "validUntil" && this.institute.captureValidUntil !== 'DISABLED' && Object.prototype.hasOwnProperty.call(this.value.scanData[dataField], "checked") && !this.value.scanData[dataField].checked){
                  return true
                }
                if (this.displayAddressData && dataField === "address") {
                    const addressObject = this.value.scanData[dataField]
                    for (const addressField in addressObject) {
                        if (Object.prototype.hasOwnProperty.call(addressObject[addressField], "checked") && !addressObject[addressField].checked) {
                            return true;
                        }
                    }
                }
            }
            return false
        }
    }
}
</script>
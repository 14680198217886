<template>
    <v-app>
        <template v-if="!isLoginPending && initializeDone">
            <template v-if="isUserLoggedin">
                <div class="d-flex flex-column flex-grow-1" style="max-height: 100%">
                    <app-navigation class="d-flex flex-column flex-grow-1"></app-navigation>
                    <router-view class="d-flex flex-column flex-grow-1"/>
                </div>
                <app-success-dialog v-model="successDialog" :success="success"/>
                <app-error-dialog v-model="errorDialog" :error="error"/>
            </template>
            <template v-if="!isUserLoggedin && !noInstituteInfo">
                <app-login></app-login>
            </template>
            <template v-if="noInstituteInfo">
                <app-no-institute-data></app-no-institute-data>
            </template>
        </template>
        <template v-else>
            <v-overlay :value="true">
                <v-progress-circular indeterminate size="64">Starte App</v-progress-circular>
            </v-overlay>
        </template>
    </v-app>
</template>

<script>
import NavigationComponent from "./components/Navigation/Navigation.vue"
import Login from "./views/Login"
import NoInstituteData from "./views/NoInstituteData"
import SuccessDialog from "./components/SuccessDialog"
import ErrorDialog from "./components/ErrorDialog"

export default {
    name: 'App',
    components: {
        appLogin: Login,
        appNoInstituteData: NoInstituteData,
        appNavigation: NavigationComponent,
        appSuccessDialog: SuccessDialog,
        appErrorDialog: ErrorDialog

    },
    methods: {
        initialize() {
            var insID = this.$route.query.insID
            if (insID !== undefined && !this.$store.getters.getInsID && !this.isUserLoggedin) {
                this.$store.dispatch("changeInsID", insID)
            } else {
                //console.log("reloading ins id")
                this.$store.dispatch("reloadInsID")
            }
            //console.log("doing stuff")
            this.insID = this.$store.getters.getInsID
            //console.log(this.insID)
            this.$store.dispatch("loadInfo").then(() => {
                this.initializeDone = true
            })
            let query = Object.assign({}, this.$route.query);
            delete query.insID;
            this.$router.replace({
                query
            });
        }
    },
    computed: {
        isUserLoggedin() {
            //console.log(this.$store.getters.isUserLoggedin)
            return this.$store.getters.isUserLoggedin;
        },
        isLoginPending() {
            return this.$store.getters.isLoginPending;
        },
        noInstituteInfo() {
            return this.$store.getters.getNoInfo;
        },
        success() {
            return this.$store.getters.getSuccess
        },
        error() {
            return this.$store.getters.getError
        },
    },
    watch: {
        isUserLoggedin(isLoggedIn) {
            if (isLoggedIn) {
                //this.$router.push('/home/mandanten')
            }
        },
        error() {
            this.errorDialog = true
        },
        success() {
            //console.log(this.success)
            this.successDialog = true
        },
    },

    data: () => ({
        insID: undefined,
        initializeDone: false,
        errorDialog: false,
        successDialog: false,
    }),
    created() {
        this.$store.dispatch("createInterceptor")
        this.$store.dispatch("relogin").then(() => {
            this.initialize()
        })
    }
};
</script>
<style>
.v-window-item {
    background-color: #f0f0f0 !important;
}

.v-main__wrap {
    background-color: #f0f0f0 !important;
}

.main-header {
    height: 96px;
    width: 100%;
    background-color: red;
    display: flex;
}

.main-header-img {
    width: 33%;
}

.main-header-logout {
    width: 33%;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.main-header-language {
    width: 33%;
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
}

.top-menu-buttons {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: white !important;
}

.main-v-card-wrapper {
    border: 0px !important;
    background-color: #f0f0f0 !important;
}

.v-tabs-slider-wrapper {
    color: red;
    height: 4px !important;
}

.home-tab-outer-wrapper {
    background-color: #f0f0f0 !important;
    max-width: 930px;
    margin-right: auto;
    margin-left: auto;
}

.home-custom-tab {
    background-color: #666;
    margin-right: 5px;
    margin-left: 5px;
    border-top: 20px solid #f0f0f0;
    width: 200px;
}

.row {
    margin: 0px;
}

.row-outer-container {
    background-color: #f0f0f0
}

.row-toolbar {
    margin-bottom: 20px !important;
    background-color: #666 !important;
}

#row-button-toggle {
    background-color: white;
}

#row-button-descending,
#row-button-ascending {
    background-color: white;
}

#row-button-descending span i,
#row-button-ascending span i {
    color: red;
}

.cardview-row-wrapper {
    display: flex;
    justify-content: space-between
}

.row-card-wrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(255, 0, 0, 0.35) !important;
    transform: scale(1.01);
}

.image-row-wrapper {
    padding: 30px;
    width: 50%;
    display: flex;
    justify-content: center
}

.image-row-wrapper div {
    margin-top: auto;
    margin-bottom: auto;
}

.text-row-wrapper {
    padding: 10px;
    width: 100%;
    display: block;
    display: block;
    text-align: center;
}

.texticon-row-wrapper {
    padding-left: 16px;
    display: flex;
    justify-content: left;
}

.row-footer-spacer {
    width: 33%;
}

.row-items-per-page span {
    color: red;
}

.v-list-item__content_value {
    text-align: left;
    word-break: break-word;
}

.v-list-item__content_description {
    margin-bottom: auto;
}

.v-application {
    height: 100vh;
    display: flex;
    background-color: #f0f0f0 !important;
}
</style>

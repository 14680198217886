import axios from "axios";

const state = {
    versionNumber: ""

}
const mutations = {

    setVersionNumber(state, value) {
        state.versionNumber = value;
    },
}
const actions = {
    async fetchVersionNumber({
                                 commit
                             }) {
        try {
            var versionNumberResponse = (await axios.get(`version`)).data;

            console.log(versionNumberResponse)
            commit("setVersionNumber", versionNumberResponse);
        } catch (error) {
            console.log(error)
        }
    },
}
const getters = {
    getVersionNumber(state) {
        return state.versionNumber;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}
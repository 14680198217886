<template>
  <v-row justify="center">
    <v-dialog
        v-model="internalValue"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Interesent erstellen</span>
          <v-spacer></v-spacer>
          <v-btn text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert v-if="personsFoundHint"
                   type="warning"
          >Es wurden Personen zu Ihrem Ligitimationsdokument gefunden. Bitte prüfen Sie ob ein Interesent
            erstellt werden muss.
          </v-alert>


          <div style="width: 100%; height: 92%">
            <template v-if="showFirstName">
              <v-alert
                  type="warning"
              >Es wurden mehrere Vornamen gefunden. Bitte definieren Sie, ob es sich um Vornamen
                (Rufnamen) oder weitere Vornamen handelt, bevor Sie mit der Anlage des Interessenten/
                der Person fortfahren.
              </v-alert>
              <v-text-field v-model="potentialCustomerDTO.firstName" label="Vorname" type="text">
                <template v-slot:append>
                  <v-icon color="orange">mdi-exclamation-thick</v-icon>
                </template>
              </v-text-field>
              <v-text-field v-model="potentialCustomerDTO.additionalFirstNames" label="Weitere Vornamen"
                            type="text">
                <template v-slot:append>
                  <v-icon color="orange">mdi-exclamation-thick</v-icon>
                </template>
              </v-text-field>
            </template>

            <app-svz-component is-required title="Interessenten Typ" :items="PotentialCustomerType"
                               item-value="id"
                               :isCompany="isCompany" item-text="shortTitle"
                               v-model="potentialCustomerDTO.potentialCustomerType"
                               label="Interessenten Typ"></app-svz-component>
            <app-svz-component is-required title="Anrede" :items="Salutation" item-value="id"
                               :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.salutationSvzId"
                               label="Anrede"></app-svz-component>
            <app-svz-component title="Titel" :items="Title" item-value="id" :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.titleSvzId"
                               label="Titel"></app-svz-component>
            <app-svz-component title="Berufliche Stellung" :items="ProfessionalPosition" item-value="id"
                               :isCompany="isCompany" item-text="shortTitle"
                               v-model="potentialCustomerDTO.professionalPositionSvzId"
                               label="Berufliche Stellung"></app-svz-component>
            <app-svz-component title="Branche" :items="Industry" item-value="id" :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.industrySvzId"
                               label="Branche"></app-svz-component>
            <app-svz-component title="Familienstand" :items="MaritalStatus" item-value="id"
                               :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.maritalStatusSvzId"
                               label="Familienstand"></app-svz-component>
            <app-svz-component title="Güterstand" :items="PropertyStatus" item-value="id"
                               :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.propertyStatusSvzId"
                               label="Güterstand"></app-svz-component>
            <app-svz-component title="Organisations Einheit" :items="OrganizationalUnit" item-value="id"
                               :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.organizationalUnit"
                               label="Güterstand"></app-svz-component>
            <app-svz-component title="Service Center" :items="ServiceCenter" item-value="id"
                               :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.serviceCenter"
                               label="Güterstand"></app-svz-component>
            <app-svz-component title="Beraterplatz" :items="ConsultantPlace" item-value="id"
                               :isCompany="isCompany"
                               item-text="shortTitle" v-model="potentialCustomerDTO.consultantPlace"
                               label="Güterstand"></app-svz-component>
          </div>
        </v-card-text>
        <v-card-actions style="height: 8%">
          <v-spacer></v-spacer>
          <v-btn :disabled="createPotentialCustomerDisabled" color="primary"
                 :loading="potentialCustomerLoading"
                 @click="createPotentialCustomer(potentialCustomerDTO)">
            Interessenten Anlegen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import SvzComponent from "@/components/Scan/ScanEdit/InputComponents/SvzComponent.vue";

export default {
  methods: {
    createPotentialCustomer() {
      const toSend = JSON.parse(JSON.stringify(this.potentialCustomerDTO))
      this.$emit("createPotentialCustomer", toSend)
    },
    close() {
      this.internalValue = false
    },
    back() {
      this.$emit("back")
    },
    changeFirstName() {
      if (this.FirstName.includes(" ")) {
        this.potentialCustomerDTO.firstName = this.FirstName;
        this.potentialCustomerDTO.additionalFirstNames = null
        this.showFirstName = true
        console.log(this.potentialCustomerDTO)
      } else {
        this.potentialCustomerDTO.firstName = null;
        this.potentialCustomerDTO.additionalFirstNames = null;
        this.showFirstName = false
      }

    }
  },
  data() {
    return {
      potentialCustomerDTO: {
        salutationSvzId: null,
        potentialCustomerType: null,
        titleSvzId: null,
        professionalPositionSvzId: null,
        industrySvzId: null,
        maritalStatusSvzId: null,
        propertyStatusSvzId: null,
        countryCodeSvzId: null,
        firstName: null,
        additionalFirstNames: null,
        organizationalUnit: null,
        serviceCenter: null,
        consultantPlace: null
      },
      showFirstName: false
    }
  },
  components: {
    appSvzComponent: SvzComponent
  },
  props: {
    scan: {
      required: true,
      type: Object
    },
    svzs: {
      required: true,
      type: Array
    },
    isCompany: {
      required: true,
      type: Boolean
    },
    potentialCustomerLoading: {
      required: true,
      type: Boolean
    },
    value: {
      required: true,
      type: Boolean
    },
    personsFoundHint: {
      required: true,
      type: Boolean
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.potentialCustomerDTO = {
          firstName: null,
          additionalFirstNames: null,
          salutationSvzId: null,
          potentialCustomerType: null,
          titleSvzId: null,
          professionalPositionSvzId: null,
          industrySvzId: null,
          maritalStatusSvzId: null,
          propertyStatusSvzId: null,
          countryCodeSvzId: null,
          organizationalUnit: null,
          serviceCenter: null,
          consultantPlace: null
        }
        this.changeFirstName()
      }
    },
  },
  computed: {
    FirstName() {
      return this.scan.scanData.firstNames.fieldValue;
    },
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    },
    Salutation() {
      return this.svzs.filter(item => item.svzType === "SALUTATION" && item.enabled);
    },
    Title() {
      return this.svzs.filter(item => item.svzType === "TITLE" && item.enabled);
    },
    ProfessionalPosition() {
      return this.svzs.filter(item => item.svzType === "PROFESSIONAL_POSITION" && item.enabled);
    },
    Industry() {
      return this.svzs.filter(item => item.svzType === "INDUSTRY" && item.enabled);
    },
    MaritalStatus() {
      return this.svzs.filter(item => item.svzType === "MARITAL_STATUS" && item.enabled);
    },
    PropertyStatus() {
      return this.svzs.filter(item => item.svzType === "PROPERTY_STATUS" && item.enabled);
    },
    CountryCode() {
      return this.svzs.filter(item => item.svzType === "COUNTRY_CODE" && item.enabled);
    },
    Gender() {
      return this.svzs.filter(item => item.svzType === "GENDER" && item.enabled);
    },
    OrganizationalUnit() {
      return this.svzs.filter(item => item.svzType === "ORGANIZATIONAL_UNIT" && item.enabled);
    },
    ServiceCenter() {
      return this.svzs.filter(item => item.svzType === "SERVICE_CENTER" && item.enabled);
    },
    ConsultantPlace() {
      return this.svzs.filter(item => item.svzType === "CONSULTANT_PLACE" && item.enabled);
    },
    PotentialCustomerType() {
      return this.svzs.filter(item => item.svzType === "POTENTIAL_CUSTOMER_TYPE" && item.enabled);
    },
    createPotentialCustomerDisabled() {
      return this.potentialCustomerDTO.salutationSvzId == null || this.potentialCustomerDTO.potentialCustomerType == null
    }
  },
  created() {
    this.$emit("loadSvzs")
  }
}
</script>

<style>
.v-stepper__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

import axios from "axios";

const state = {
    userNotifications: []
}
const mutations = {
    setUserNotifications(state, value) {
        state.userNotifications = value;
    },
    setUserNotificationNotSeen(state, id) {
        state.userNotifications = [
            ...state.userNotifications.filter(element => element.id !== id),
        ]
    },
    setAllUserNotificationNotSeen(state) {
        state.userNotifications = []
    }

}
const actions = {
    async loadUserNotification({
                                   commit
                               }) {
        try {
            var userNotificationResponse = (await axios.get(`/userNotification/me/notseen`)).data;

            commit("setUserNotifications", userNotificationResponse);
        } catch (error) {
            console.log(error)
        }
    },
    async setUserNotificationSeen({
                                      commit
                                  }, userNotificationID) {
        try {
            await axios.put(`/userNotification/${userNotificationID}`);
            commit("setUserNotificationNotSeen", userNotificationID);
        } catch (error) {
            console.log(error)
        }
    },
    async setUserNotificationSeenAll({
                                         commit
                                     }) {
        try {
            await axios.put(`/userNotification/me/all`);
            commit("setAllUserNotificationNotSeen");
        } catch (error) {
            console.log(error)
        }
    },
}
const getters = {
    getUserNotifications(state) {
        return state.userNotifications;
    },
}
export default {
    state,
    actions,
    mutations,
    getters
}
<template>
    <div v-if="!isCompany && value" class="step-one-details-item-wrapper">
        <div class="step-one-details-label-outer-wrapper">
            <div class="step-one-details-item-label-inner-wrapper">
                <label :for="'input-' + value.fieldValue">{{ title }}</label>
            </div>
        </div>
        <div class="step-one-details-input-outer-wrapper">
            <div class="step-one-details-item-inner-wrapper">
                <v-autocomplete v-model="value.fieldValue" :items="items" :item-text="itemText" :item-value="itemValue"
                                :return-object="false" hide-selected :disabled="value.checked"
                                :label="label"></v-autocomplete>
            </div>
        </div>
        <div class="step-one-details-checkbox-outer-wrapper">
            <div class="step-one-details-item-inner-wrapper">
                <v-checkbox v-model="value.checked" :disabled="value.fieldValue === '' && !value.checked"
                            class="step-one-checkbox" color="#ff0000" hide-details></v-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isCompany: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        itemText: {
            type: String,
            required: true
        },
        itemValue: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    },
};
</script>

<style scoped>
@import 'Style.css';
</style>
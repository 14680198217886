<template>
    <v-app id="inspire">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-card class="elevation-12">
                            <v-toolbar dark color="primary">
                                <v-toolbar-title>Anmeldung LegiScan_neo</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field v-model="username" id="username" prepend-icon="mdi-account"
                                                  name="login"
                                                  label="Benutzername" type="text"
                                                  v-on:keyup.enter="login"></v-text-field>
                                    <v-text-field v-model="password" id="password" prepend-icon="mdi-lock"
                                                  name="password"
                                                  label="Passwort" type="password"
                                                  v-on:keyup.enter="login"></v-text-field>
                                </v-form>
                                <p v-if="errorCode" class="text-center error-message">
                                    {{ errorTitle }}
                                </p>
                                <v-alert v-for="globalNotification in globalNotifications"
                                         border="left"
                                         :color="getColor(globalNotification.level)"
                                         outlined
                                         :key="globalNotification.id"
                                >
                                    <div class="text-h6">
                                        {{ globalNotification.subject }}
                                    </div>
                                    <div>
                                        {{ globalNotification.body }}
                                    </div>
                                </v-alert>
                            </v-card-text>
                            <v-card-actions>
                                <p style="margin-bottom: 0px; font-size: 12px; color:grey">Version: {{
                                    versionNumber
                                    }}</p>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click.stop="login">Anmelden</v-btn>
                            </v-card-actions>
                            <v-progress-linear indeterminate v-show="isLoading"></v-progress-linear>

                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            username: "",
            password: "",
            isLoading: false
        }
    },

    name: 'Login',
    props: {
        source: String,
    },

    computed: {
        errorTitle() {
            switch (this.errorCode) {
                case 991:
                    return "Benutzername oder Passwort falsch eingegeben.";
                case 981:
                    return "Ihr OSPlus-Passwort ist abgelaufen. Bitte nehmen Sie in OSPlus eine Passwortänderung vor.";
                case 992:
                    return "Sie haben in OSPlus leider keine Berechtigung, Legitimationen zu bearbeiten.";
                case 100001:
                    return "Sie wurden automatisch vom System abgemeldet."
                default:
                    return "Ein unerwarteter Serverfehler ist aufgetreten.";
            }
        },
        errorCode() {
            return this.$store.getters.getErrorCode
        },
        versionNumber() {
            return this.$store.getters.getVersionNumber
        },
        globalNotifications() {
            return this.$store.getters.getGlobalNotifications
        }
    },
    methods: {
        getColor(level) {
            switch (level) {
                case "INFO":
                    return "grey"
                case "WARNING":
                    return "#A0522D"
                case "ERROR":
                    return "red"
                default:
                    return "grey"
            }
        },
        login() {
            //console.log("Logging in")
            this.isLoading = true
            this.$store.dispatch("loginUser", {
                username: this.username.toUpperCase(),
                password: this.password,
                insUrlToken: this.$store.getters.getInsID
            }).then(() => {
                this.isLoading = false
                //console.log(this.insID)
                this.$store.dispatch("relogin");
            })
            this.errorOccured = false
        }
    },
    mounted() {
        this.$store.dispatch("fetchVersionNumber")
        this.$store.dispatch("loadGlobalNotificationByInstitute", this.$store.getters.getInsID)
    },
};
</script>

<style scoped>
.error-message {
    color: #f00
}
</style>
<template>
    <div>
      <v-overlay
          :absolute="true"
          :value="true"
      >
      </v-overlay>
        <app-scan-edit v-if="SelectedScan" :scan="SelectedScan" @saveDocumentType="saveDocumentType"
                       :isCompany="isCompany" :countries="countries" @closeEdit="closeEdit"
                       :potentialCustomerEnabled="potentialCustomerEnabled"></app-scan-edit>
    </div>

</template>
<script>
import ScanEdit from "../../components/Scan/ScanShow.vue"

export default {
    components: {
        appScanEdit: ScanEdit
    },
    computed: {
        potentialCustomerEnabled() {
            return this.$store.getters.getInfo.potentialCustomerCreate
        },
        ScanID() {
            return this.$route.params.scanID
        },
        SelectedScan() {
            return this.$store.getters.getSelectedScan;
        },
        countries() {
            return this.$store.getters.getCountries
        },
        isCompany() {
            return this.SelectedScan.scanData.dtype.fieldValue && this.SelectedScan.persontype && this.SelectedScan.persontype === "JP"
        }
    },
    methods: {
        closeEdit() {
            this.$router.push({name: "ScanIndex"})
            this.$store.dispatch("unloadScan");
        },
        loadScan(newID) {
            if (newID) {
                this.$store.dispatch("loadScan", newID);
            }
        },
        loadCountries() {
            this.$store.dispatch("loadCountries");

        },
        saveDocumentType(documentType) {
            this.$store.dispatch("setDType", documentType)
        }
    },
    watch: {
        ScanID(newID) {
            this.loadScan(newID);
        }
    },
    created() {
        this.loadScan(this.ScanID);
        this.loadCountries()
    }
}
</script>
import axios from "axios";

const state = {
    scan: [],
    labels: {
        placeOfBirth: "Geburtsort",
        validFrom: "Ausstellungsdatum",
        validUntil: "Ablaufdatum",
        lastname: "Nachname",
        dateOfbirth: "Geburtsdatum",
        issuer: "Ausstellende Behörde",
        firstname: "Vorname",
    },
    addressUpdateRequired: false,
    addressCreateRequired: false,
    firstNameUpdateRequired: false,
    birthNameUpdateRequired: false,
    selectedScan: null
}
const mutations = {
    setAddressUpdateRequired(state, addressUpdateRequired) {
        state.addressUpdateRequired = addressUpdateRequired;
    },
    setAddressCreateRequired(state,addressCreateRequired){
        state.addressCreateRequired = addressCreateRequired;
    },
    setFirstNameUpdateRequired(state, firstNameUpdateRequired) {
        state.firstNameUpdateRequired = firstNameUpdateRequired
    },
    setBirthNameUpdateRequired(state, birthNameUpdateRequired){
        state.birthNameUpdateRequired = birthNameUpdateRequired
    },
    setScan(state, events) {
        state.scan = events;
    },
    addScan(state, newScan) {
        var existingItems = state.scan.map(item => item.id)
        if (existingItems.indexOf(newScan.id) == -1) {
            state.scan.unshift(newScan)
        } else {
            var existingScan = state.scan.find(obj => {
                return obj.id === newScan.id
            })
            var existingIndex = state.scan.indexOf(existingScan)
            var existingImages = existingScan.images

            newScan.images = existingImages
            state.scan[existingIndex].id = newScan.id ? newScan.id : "",
                state.scan[existingIndex].status = newScan.status ? newScan.status : "",
                state.scan[existingIndex].dtype = newScan.scanData.dtype ? newScan.scanData.dtype : "",
                state.scan[existingIndex].persontype = newScan.persontype ? newScan.persontype : "",
                state.scan[existingIndex].images = existingImages,
                state.scan[existingIndex].security = newScan.security ? newScan.security : "",
                state.scan[existingIndex].scanData = newScan.scanData
            state.scan[existingIndex].pdfData = newScan.pdfData

        }
    },
    clearScans() {
        state.scan = []
    },
    setSelectedScan(state, newScan) {
        state.selectedScan = newScan
    },
    setSelectedScanOspResource(state, ospResource) {
        const selectedScan = JSON.parse(JSON.stringify(state.selectedScan))
        selectedScan.ospResource = ospResource
        state.selectedScan = selectedScan
    },
    setSelectedScanOspResourcePotentialCustomer(state, potentialCustomerCreated) {
        const selectedScan = JSON.parse(JSON.stringify(state.selectedScan))
        selectedScan.potentialCustomerCreated = potentialCustomerCreated
        state.selectedScan = selectedScan
    }
}
const actions = {
    async loadScan({
                       commit
                   }, scanId) {
        const scan = (await axios.get(`scan/${scanId}`)).data;
        const images = [];
        for (const image of scan.images) {
            const picture = {
                id: image.id,
                rank: image.rank,
                createdAt: image.created_at,
                baseImage: image.baseImagePath !== "" ? (await axios.get(image.baseImagePath)).data : "",
                processedImage: image.processedImagePath !== "" ? (await axios.get(image.processedImagePath)).data : "",
                baseImagePath: image.baseImagePath,
                processedImagePath: image.processedImagePath,
            };
            images.push(picture)
        }
        scan.images = images
        commit("setSelectedScan", scan)
    },
    async unloadScan({
                         commit
                     }) {
        commit("setSelectedScan", null)
    },
    async loadScans({
                        commit
                    }) {
        try {
            var scanResponse = (await axios.get(`user/me/scan`)).data;

            scanResponse.sort((a, b) => (parseInt(a.createdAt) > parseInt(b.createdAt)) ? 1 : ((parseInt(b.createdAt) >
                parseInt(a.createdAt)) ? -1 : 0))
            scanResponse.forEach((scan) => {
                try {

                    var images = []
                    scan.images.sort((a, b) => (parseInt(a.rank) > parseInt(b.rank)) ? 1 : ((parseInt(b.rank) >
                        parseInt(a.rank)) ? -1 : 0))
                    scan.images.forEach((image) => {
                        var picture = {
                            id: image.id,
                            rank: image.rank,
                            createdAt: image.created_at,
                            baseImage: null,
                            processedImage: null,
                            baseImagePath: image.baseImagePath,
                            processedImagePath: image.processedImagePath,
                        }

                        images.push(picture)
                    })

                    var newScan = {
                        id: scan.id ? scan.id : "",
                        status: scan.status ? scan.status : "",
                        createdAt: scan.createdAt ? scan.createdAt : "",
                        dtype: scan.scanData.dtype ? scan.scanData.dtype : "",
                        persontype: scan.persontype ? scan.persontype : "",
                        pdfData: scan.pdfData ? scan.pdfData : undefined,
                        images: images,
                        security: scan.security ? scan.security : "",
                        //imagesRef: scan.images,
                        scanData: scan.scanData ? scan.scanData : "",
                    }
                    commit("addScan", newScan);

                } catch (x) {
                    //console.log(x)
                    console.log("Couldn't parse Scan", scan)
                }


            })
        } catch (error) {
            console.log(error)
        }
    },
    async loadVisibleImages({
                                commit, state
                            }, params) {

        var onlyShow = params.status
        var filteredItems = []
        state.scan.forEach(element => {
            if (onlyShow.toLowerCase() === element.status.toLowerCase() || onlyShow
                .toLowerCase() === "alle") {
                filteredItems.push(element)
            }
        });
        for (var i = (params.page - 1) * params.itemsPerPage; i < params.page * params.itemsPerPage; i++) {
            if (filteredItems[i]) {
                var scan = filteredItems[i]
                if (scan.images.length !== 0) {
                    if (!scan.images[0].processedImage) {
                        if (scan.images[0].processedImagePath !== "") {
                            await axios.get(scan.images[0].processedImagePath).then(data => {
                                scan.images[0].processedImage = data.data
                            })
                        }
                        commit("addScan", scan)
                    }
                }
            }
        }
    },
    async copyScan({dispatch}, scanID) {
        await axios.post(`scan/${scanID}/copies`);
        dispatch("loadScans");

    },
    async restoreScan({dispatch}, scanID) {
        await axios.post(`scan/${scanID}/restore`);
        dispatch("loadScan", scanID);
        dispatch("loadScans");
    },
    async setDType({
                       // eslint-disable-next-line no-unused-vars
                       commit, dispatch
                   }, newData) {
        return new Promise((resolve, reject) => {
            axios.put(`scan/${newData.scanId}/type`, newData.documentType, {headers: {"Content-Type": "text/plain"}}).then(data => {
                var newScan = {
                    id: data.data.id ? data.data.id : "",
                    status: data.data.status ? data.data.status : "",
                    createdAt: data.data.createdAt ? data.data.createdAt : "",
                    dtype: data.data.scanData.dtype ? data.data.scanData.dtype : "",
                    persontype: data.data.persontype ? data.data.persontype : "",
                    images: data.data.images,
                    security: data.data.security ? data.data.security : "",
                    //imagesRef: scan.images,
                    scanData: data.data.scanData ? data.data.scanData : "",
                }
                commit("addScan", newScan);
                resolve(data);
                dispatch("loadScan", newData.scanId);
            }).catch(err => {
                reject(err)
            })
        })
    },

    downloadPdf({
                    // eslint-disable-next-line no-unused-vars
                    commit, dispatch
                }, scan) {

        axios.get(`scan/${scan.id}/pdf/name`).then(name => {
            var filename = name.data
            axios.get(`scan/${scan.id}/pdf`, {responseType: 'blob'}).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }).catch(err => {
                dispatch("createError", err.response)
            })
        }).catch(err => {
            dispatch("createError", err.response)
        })
    },
    async loadImages({
                         // eslint-disable-next-line no-unused-vars
                         dispatch
                     }, scan) {
        for (const image of scan.images) {
            if (image.processedImage == null) {
                image.processedImage = (await axios.get(image.processedImagePath)).data;
            }
            if (image.baseImage == null) {
                image.baseImage = (await axios.get(image.baseImagePath)).data;
            }
        }
    },

    async openScan({
                       // eslint-disable-next-line no-unused-vars
                       dispatch
                   }, id) {
        await axios.get(`scan/${id}/open`)
    },

    async editScan({
                       dispatch, commit
                   }, item) {
        const scan = JSON.parse(JSON.stringify(item.scan));
        if (item.imagesChanged) {
            for (const image of scan.images) {
                image.processedImagePath = (await axios.post(image.processedImagePath, {
                    data: image.processedImage
                })).data
            }
        }
        for (const image of scan.images) {
            image.baseImage = null
            image.processedImage = null
        }

        await axios.put(`scan/${scan.id}`, scan).then(response => {
            dispatch("changeActiveError", false)
            commit("addScan", response.data);
        }).catch(err => {
            dispatch("createError", err.response)
            throw err
        })
    },
    async deleteScan({
                         dispatch
                     }, id) {
        await axios.delete(`/scan/${id}`).then(function () {
            dispatch("changeActiveError", false)
        }).catch(err => {
            dispatch("createError", err.response)
        })
        dispatch("loadScans");
    },

    async clearScans({
                         commit
                     }) {
        commit("clearScans")
    },
}
const getters = {
    getScans(state) {
        return state.scan;
    },
    getFieldnames(state) {
        return state.labels
    },
    getAddressUpdateRequired() {
        return state.addressUpdateRequired
    },
    getAddressCreateRequired() {
        return state.addressCreateRequired
    },
    getSelectedScan(state) {
        return state.selectedScan
    },
    getFirstNamesUpdateRequired(state) {
        return state.firstNameUpdateRequired
    },
    getBirthNameUpdateRequired(state){
        return state.birthNameUpdateRequired
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}
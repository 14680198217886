import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';
import '@mdi/font/css/materialdesignicons.css'
import ProductZoomer from 'vue-product-zoomer'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
Vue.use(Vuetify);
Vue.use(ProductZoomer)

export default new Vuetify({
    lang: {
        locales: {de},
        current: 'de',
    },
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#FF0000',
                secondary: "#FF0000",
                accent: "#FF0000",
                error: "#FF0000",
            },
            dark: {
                primary: "#FF0000",
            },
        },
    },
});

<template>
    <div class="text-center">
        <v-card>
            <v-card-title>
                Dokumentenart auswählen
            </v-card-title>
            <v-divider></v-divider>

            <p>Dokumentenart</p>
            <v-autocomplete v-model="documentType" :items="documents"
                            item-text="shortDesc" :return-object="false" item-value="ospDocType" hide-selected
                            label="Dokumentenart">
            </v-autocomplete>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="show = false">
                    Abbrechen
                </v-btn>
                <v-btn color="primary" text @click="save">
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            documentType: null,
            show: true
        }
    },
    props: {
        scan: Object
    },
    computed: {
        documents() {
            var docs = this.$store.getters.getInstituteDocuments
            docs.sort(function (a, b) {
                return a.position - b.position
            });
            return docs
        },
    },
    methods: {
        save() {
            this.$emit('save', {documentType: this.documentType, scanId: this.scan.id})
        }
    },
    created() {
    }
}
</script>

<style scoped>
.pickDoc-title-wrapper {
    color: #fff
}
</style>
<template>
    <v-row>
        <v-dialog v-model="show" fullscreen hide-overlay :retain-focus="false" transition="dialog-top-transition">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.stop="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Archivbild bearbeiten</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn dark text @click="logImg">
                        Speichern
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <tui-image-editor :include-ui="useDefaultUI" :options="options" ref="tuiImageEditor"></tui-image-editor>
        </v-dialog>
    </v-row>

</template>
<script>
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import {ImageEditor} from '@toast-ui/vue-image-editor';


export default {
    data() {
        return {
            useDefaultUI: true,
            options: {
                includeUI: {
                    loadImage: {
                        path: this.source,
                        name: 'SampleImage'
                    },
                    locale: {
                        ZoomIn: "Vergrößern",
                        ZoomOut: "Verkleinern",
                        Custom: "Freihändig",
                        Load: "Laden",
                        Undo: "Rückgängig",
                        Redo: "Wiederherstellen",
                        Reset: "Zurücksetzen",
                        Delete: "Funktion ausblenden",
                        DeleteAll: "Disable Function",
                        Crop: "Zuschneiden",
                        Flip: "Spiegeln",
                        Rotate: "Drehen",
                        Apply: "Anwenden",
                        Cancel: "Abbrechen",
                        "Flip X": "Vertikal spiegeln",
                        "Flip Y": "Horizontal spiegeln",
                        Range: "Grad",
                    },
                    menu: ['crop', 'rotate'],
                    //initMenu: 'crop',
                    uiSize: {
                        width: "calc(100vw)",
                        height: "calc(100vh - 64px)"
                    },
                    menuBarPosition: 'bottom'
                },
                selectionStyle: {
                    cornerSize: 20,
                    rotatingPointOffset: 70
                },
                usageStatistics: false,
            },
        };
    },
    props: {
        value: Boolean,
        source: String
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    created() {
        setTimeout(() => {
            this.changeRotateButtons()
        }, 500);
    },
    watch: {
        show(visible) {
            if (visible) {
                setTimeout(() => {
                    this.$refs.tuiImageEditor.invoke('loadImageFromURL', this.source, 'SampleImage')
                }, 50);
            }
        },
    },
    components: {
        'tui-image-editor': ImageEditor,
    },
    methods: {
        changeRotateButtons() {
            const node = document.querySelector('.tui-image-editor-button.clockwise');
            if (node) {
                node.querySelector('label').innerHTML = 90
                const div = document.createElement("div");
                div.style.width = "100%"
                div.style.height = "100%"
                div.style.position = "absolute"
                div.style.top = "0px"
                div.style.zIndex = "10"
                div.style.opacity = "1"
                div.style.background = "transparent"
                node.onclick = (function (e) {
                    e.stopPropagation();
                });
                div.onclick = this.rotateClockwise

                node.appendChild(div);
                const node2 = document.querySelector('.tui-image-editor-button.counterclockwise');
                node2.querySelector('label').innerHTML = -90
                const div2 = document.createElement("div");
                div2.style.width = "100%"
                div2.style.height = "100%"
                div2.style.position = "absolute"
                div2.style.top = "0px"
                div2.style.zIndex = "10"
                div.style.opacity = "1"
                div2.style.background = "transparent"
                node2.onclick = (function (e) {
                    e.stopPropagation();
                });
                div2.onclick = this.rotateCounterclockwise;
                node2.appendChild(div2);

                this.setupUIElement('.tui-image-editor-button.preset.preset-square', "Personalausweis", 1150, 750)
                this.setupUIElement('.tui-image-editor-button.preset.preset-3-2', "Reisepass", 1600, 2200)
                this.setupUIElement('.tui-image-editor-button.preset.preset-7-5', "DIN-A5", 2000, 2828)
                this.setupUIElement('.tui-image-editor-button.preset.preset-4-3', "DIN-A4", 2480, 3507)
            } else {
                setTimeout(() => {
                    this.changeRotateButtons()
                }, 500);
            }
        },
        // eslint-disable-next-line no-unused-vars
        setupUIElement(selector, label, cropWidth, cropHeight) {

            const newNode = document.querySelector(selector);
            newNode.querySelector('label').innerHTML = label
            const newDiv = document.createElement("div");
            newDiv.style.width = "100%"
            newDiv.style.height = "100%"
            newDiv.style.position = "absolute"
            newDiv.style.top = "0px"
            newDiv.style.zIndex = "10"
            newDiv.style.opacity = "1"
            newDiv.style.background = "transparent"
            const that = this;
            newNode.onclick = (function (e) {
                e.stopPropagation();
                that.crop(cropWidth, cropHeight)
            });
            //        newDiv.onclick = "this.crop(cropWidth, cropHeight)";
            newNode.appendChild(newDiv);
        },
        logImg() {
            //this.$dispatch("saved",this.$refs.tuiImageEditor.invoke('toDataURL'))
            const img = new Image();
            img.src = this.$refs.tuiImageEditor.invoke('toDataURL')
            const that = this
            img.onload = function () {
                const c = document.createElement('canvas');
                c.width = img.width;
                c.height = img.height;
                const ctx = c.getContext("2d");
                ctx.fillStyle = 'white'
                ctx.fillRect(0, 0, c.width, c.height);
                ctx.drawImage(img, 0, 0, img.width, img.height);
                const url = c.toDataURL('image/jpeg', 1);
                that.$emit('saved', url)
                that.show = false
            }
        },
        rotateClockwise() {
            this.$refs.tuiImageEditor.invoke("rotate", 90)
        },
        rotateCounterclockwise() {
            this.$refs.tuiImageEditor.invoke("rotate", -90)
        },
        crop(width, height) {
            const cropzone = this.$refs.tuiImageEditor.invoke("getCropzoneRect");
            cropzone.left = 0
            cropzone.width = width
            cropzone.top = 0
            cropzone.height = height
            this.$refs.tuiImageEditor.invoke("crop", cropzone)
        }
    }
}
</script>
<style>
.tui-image-editor-header-logo img {
    display: none;
}

.tui-image-editor-header-buttons {
    display: none;
}


.tui-image-editor-button.preset.preset-16-9 {
    display: none !important;
}

.tui-image-editor-button.preset.preset-5-4 {
    display: none !important;
}

.tie-btn-delete,
.tie-btn-deleteAll,
.tie-btn-history {
    display: none !important;
}

.tui-image-editor-help-menu li:nth-last-of-type(3),
.tui-image-editor-help-menu li:nth-last-of-type(2) {
    display: none !important;
}

.tui-image-editor-help-menu.top li:nth-of-type(1),
.tui-image-editor-help-menu.top li:nth-of-type(2),
.tui-image-editor-help-menu.top li:nth-of-type(3) {
    display: none !important;
}

.tui-image-editor-help-menu {
    width: auto !important;
}
</style>